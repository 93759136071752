import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CircleIcon from "@mui/icons-material/Circle";

import Table from "../../components/Table";
import "./index.css";
import { type IInvoice } from "../../../commons/interfaces";
import { useInvoices } from "../../../contexts/Invoices";
import TableParamsManager from "../../../commons/TableParamsManager";
import ModalClosingInvoice from "./ModalClosingInvoice";
import StorageManager from "../../../commons/StorageManager";
import { useStoreData } from "../../../contexts/StoreData";

interface IRow {
    Produto: string;
    QT: number;
    "Valor Unitario": number;
    total: number;
}

interface IInvoiceInStorage {
    invoiceId: number;
    tableName: string;
    createdAt: Date;
    closingDateTime?: string;
}

interface IProps {
    setInputSearchOpen: (value: boolean) => void;
    searchValue?: string;
}

export default function TableStoreOrders(props: IProps) {
    const [invoice, setInvoice] = useState<IInvoice | undefined>();
    const [rows, setRows] = useState<IRow[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [invoiceOpen, setInvoiceOpen] = useState(false);

    const { setInputSearchOpen } = props;
    const { store } = useStoreData();
    const { tableParams } = useParams();
    const { findInvoiceByTableName, closeInvoice: closeInvoiceIntegration, findInvoiceById } = useInvoices();

    const getInvoiceInStorage = (): IInvoiceInStorage | undefined => {
        const invoiceInStorage = StorageManager.getItem("invoiceData");
        if (invoiceInStorage) {
            return JSON.parse(invoiceInStorage);
        }
    };

    const addInvoiceIntoStorage = (invoiceData: IInvoiceInStorage) => {
        StorageManager.setItem("invoiceData", JSON.stringify(invoiceData));
    };

    const load = async () => {
        const invoiceInStorage = getInvoiceInStorage();
        if (invoiceInStorage) {
            if (invoiceInStorage?.closingDateTime) {
                const closingDateTime = new Date(invoiceInStorage.closingDateTime);
                const currentTime = new Date();
                const diff = (currentTime.getTime() - closingDateTime.getTime()) / 1000 / 60;
                if (diff > 60) {
                    StorageManager.clear();
                } else {
                    const response = await findInvoiceById(invoiceInStorage.invoiceId);
                    if (response) {
                        setInvoice(response);
                    }
                    return;
                }
            } else {
                const response = await findInvoiceById(invoiceInStorage.invoiceId);
                if (response?.closingDatetime) {
                    const currentTime = new Date();
                    const closingDateTime = new Date(response.closingDatetime);
                    const diff = (currentTime.getTime() - closingDateTime.getTime()) / 1000 / 60;
                    if (diff > 60) {
                        StorageManager.clear();
                    } else {
                        const response = await findInvoiceById(invoiceInStorage.invoiceId);
                        if (response) {
                            setInvoice(response);
                            addInvoiceIntoStorage({
                                ...invoiceInStorage,
                                closingDateTime: response.closingDatetime,
                            });
                        }
                        return;
                    }
                }
            }
        }

        const { storeId, tableName } = TableParamsManager.decode(tableParams!);
        const response = await findInvoiceByTableName(storeId, tableName);
        if (response) {
            setInvoice(response);
        }
    };

    useEffect(() => {
        setInputSearchOpen(false);
        load();
    }, []);

    useEffect(() => {
        if (invoice) {
            const rowsData: IRow[] = invoice.invoiceItems.map((item) => ({
                Produto: item.productName,
                QT: item.quantity,
                "Valor Unitario": item.unityPrice,
                total: item.totalPrice,
            }));
            try {
                if (!!invoice.closingDatetime && store && !!store.settings?.serviceFee.chargeServiceFee) {
                    rowsData.push({
                        Produto: store.settings?.serviceFee.feeName || "Taxa de Serviço",
                        QT: 1,
                        "Valor Unitario": invoice.feeService || 0,
                        total: invoice.feeService || 0,
                    });
                }
            } catch (error) {}

            setRows(rowsData);
            setInvoiceOpen(!invoice.closingDatetime);
        }
    }, [invoice]);

    const closeInvoice = async () => {
        if (invoice) {
            await closeInvoiceIntegration(invoice.id);
            await load();
            setModalOpen(false);
            setInvoiceOpen(false);
            const invoiceInStorage = getInvoiceInStorage();
            StorageManager.setItem(
                "invoiceData",
                JSON.stringify({ ...invoiceInStorage, closingDateTime: new Date().toISOString() })
            );
            try {
                const invoiceUpdated = await findInvoiceById(invoice.id);
                if (invoiceUpdated) {
                    setInvoice(invoiceUpdated);
                }
            } catch (error) {}
        }
    };

    return (
        <div className="table-store-orders-container">
            <ModalClosingInvoice action={closeInvoice} modalOpen={modalOpen} setModalOpen={setModalOpen} />
            {invoice ? (
                <>
                    <h2 style={{ fontFamily: "Roboto, sans-serif" }} className="invoice-title">
                        Pedido: <b>{invoice.id}</b>
                    </h2>
                    <div className="order-info">
                        <CircleIcon sx={{ width: "15px", color: invoiceOpen ? "yellow" : "red" }} />
                        <span>{invoiceOpen ? "Conta Aberta" : "Conta Fechada"}</span>
                    </div>

                    <Table rows={rows} />
                    <div className="total-price-card">
                        <span>Total:</span>
                        <span>{`R$ ${parseFloat(`${invoice?.totalPrice || 1}`).toLocaleString()}`}</span>
                    </div>
                    <div className="order-actions">
                        <Button
                            onClick={() => {
                                setModalOpen(true);
                            }}
                            variant="contained"
                            fullWidth
                            color="primary"
                            disabled={!invoiceOpen}
                        >
                            FECHAR CONTA
                        </Button>
                    </div>
                </>
            ) : (
                <h2 style={{ fontFamily: "Roboto, sans-serif" }} className="invoice-title">
                    Nenhum Pedido encontrado
                </h2>
            )}
        </div>
    );
}
