import React from "react";
import "./index.css";

export default function Footer() {
    return (
        <footer className="default-footer">
            <div>
                <p>© Sua Page. 2024. Todos os Direitos</p>
                <p className="text-footer">
                    Quando você visita ou interage com nossos sites, serviços ou ferramentas, nós ou nossos prestadores
                    de serviços autorizados podemos usar cookies para armazenar informações para ajudar a fornecer a
                    você uma experiência melhor, mais rápida e segura e para marketing propósitos.
                </p>
            </div>
        </footer>
    );
}
