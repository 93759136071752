import { useState, type CSSProperties } from "react";
import { isAxiosError } from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import PageLoading from "../../components/PageLoading";
import { passwordRecovery } from "../../../integrations/users";
import TokenManager from "../../../commons/TokenManager";
import StorageManager from "../../../commons/StorageManager";

type AlertType = "error" | "success" | "warning" | "info";
type displayAlertType = "none" | "flex";

interface IStyles {
    container: CSSProperties;
    containerContent: CSSProperties;
    input: CSSProperties;
    inputContainer: CSSProperties;
    textInfoTop: CSSProperties;
    button: CSSProperties;
    alert: CSSProperties;
}

const styles: IStyles = {
    container: {
        width: "100%",
        height: "100vh",
        padding: "50px 20px",
        fontFamily: "Roboto, sans-serif",
        display: "flex",
        flexDirection: "row",

        justifyContent: "center",
        boxSizing: "border-box",
    },
    containerContent: {
        width: "100%",
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    input: {
        width: "100%",
        maxWidth: "400px",
    },
    inputContainer: {
        width: "100%",
        margin: "10px",
    },
    textInfoTop: {
        fontSize: "20px",
        fontWeight: "400",
        marginBottom: "20px",
    },
    button: {
        width: "100%",
        maxWidth: "400px",
    },
    alert: {
        width: "100%",
    },
};

export default function PasswordRecovery() {
    const [password, setPassword] = useState<string>("");
    const [textPasswordError, setTextPasswordError] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [textConfirmPasswordError, setTextConfirmPasswordError] = useState<string>("");
    const [showPassword, setShowPassword] = useState(false);

    const [textAlert, setTextAlert] = useState<string>("");
    const [alertType, setAlertType] = useState<AlertType>("error");
    const [displayAlert, setDisplayAlert] = useState<displayAlertType>("none");

    const [isLoading, setIsLoading] = useState(false);

    const validatePassword = () => {
        if (password.length < 8) {
            setTextPasswordError("A Senha deve conter pelo menos 8 digitos");
            return false;
        }
        if (password !== confirmPassword) {
            setTextConfirmPasswordError("A senha e a confirmação de senha devem ser iguais");
            setTextPasswordError("A senha e a confirmação de senha devem ser iguais");
            return false;
        }
        setTextConfirmPasswordError("");
        setTextPasswordError("");
        return true;
    };

    const send = async () => {
        if (!validatePassword()) return;
        try {
            setIsLoading(true);
            const token = new URLSearchParams(document.location.search).get("token") || "";
            TokenManager.clear();
            TokenManager.setTokenTemp(token);
            const response = await passwordRecovery({
                newPassword: password,
            });
            if (response.status < 400) {
                const textMessage = `Sua senha foi alterada com sucesso, agora va ate a pagina de login!`;
                renderAlert("success", textMessage);
                StorageManager.clear();
                window.location.href = "/login";
            }
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (error.response.status === 401) {
                    renderAlert(
                        "error",
                        `O seu link esta expirado, por favor faça a solicitação de redefinição de senha e tente novamente!`
                    );
                } else {
                    renderAlert("error", `Não conseguimos atualizar a sua senha, tente mai tarde`);
                }
            }
        } finally {
            setIsLoading(false);
            TokenManager.clear();
        }
    };

    const renderAlert = (type: AlertType, text: string) => {
        setTextAlert(text);
        setAlertType(type);
        setDisplayAlert("flex");
    };

    return (
        <div style={styles.container}>
            <PageLoading open={isLoading} />
            <div style={styles.containerContent}>
                <p style={styles.textInfoTop}>Digite a nova senha</p>
                <div style={styles.inputContainer}>
                    <TextField
                        error={!!textPasswordError}
                        style={styles.input}
                        fullWidth
                        name="password"
                        label="Senha"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() => {
                                        setShowPassword(!showPassword);
                                    }}
                                >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                                </IconButton>
                            ),
                        }}
                    />
                    <p style={{ margin: 0, color: "red" }}>{textPasswordError}</p>
                </div>
                <div style={styles.inputContainer}>
                    <TextField
                        error={!!textConfirmPasswordError}
                        style={styles.input}
                        fullWidth
                        name="confirm-password"
                        label="Confirme sua senha"
                        type={showPassword ? "text" : "password"}
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={(event) => {
                            setConfirmPassword(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() => {
                                        setShowPassword(!showPassword);
                                    }}
                                >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                                </IconButton>
                            ),
                        }}
                    />
                    <p style={{ margin: 0, color: "red" }}>{textConfirmPasswordError}</p>
                </div>
                <Button
                    style={styles.button}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={async () => send()}
                    data-testid="button-login"
                >
                    ENVIAR
                </Button>
                <Alert style={styles.alert} sx={{ display: displayAlert }} severity={alertType}>
                    {textAlert}
                </Alert>
            </div>
        </div>
    );
}
