import { useState, type CSSProperties } from "react";
import { isAxiosError } from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { Navigate, useNavigate } from "react-router-dom";

import PageLoading from "../../components/PageLoading";
import { validateEmail } from "../../../integrations/users";
import { useSecurityContext } from "../../../contexts/Security";

type AlertType = "error" | "success" | "warning" | "info";
type displayAlertType = "none" | "flex";

interface IStyles {
    container: CSSProperties;
    containerContent: CSSProperties;
    input: CSSProperties;
    textInfoTop: CSSProperties;
    button: CSSProperties;
    alert: CSSProperties;
}

const styles: IStyles = {
    container: {
        width: "100%",
        height: "100vh",
        padding: "50px 20px",
        fontFamily: "Roboto, sans-serif",
        display: "flex",
        flexDirection: "row",

        justifyContent: "center",
        boxSizing: "border-box",
    },
    containerContent: {
        width: "100%",
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    input: {
        width: "100%",
        maxWidth: "400px",
    },
    textInfoTop: {
        fontWeight: "300",
    },
    button: {
        width: "100%",
        maxWidth: "400px",
    },
    alert: {
        width: "100%",
    },
};

export default function ValidateEmail() {
    const [code, setCode] = useState("");
    const [textAlert, setTextAlert] = useState<string>("");
    const [alertType, setAlertType] = useState<AlertType>("error");
    const [displayAlert, setDisplayAlert] = useState<displayAlertType>("none");

    const [isLoading, setIsLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const { forceRefreshToken } = useSecurityContext();
    const navigate = useNavigate();

    const send = async () => {
        try {
            setIsLoading(true);
            const response = await validateEmail(code);
            if (response.status < 400) {
                await forceRefreshToken();
                setVerified(true);
                window.location.href = "/products";
            }
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    renderAlert("error", `Codigo Invalido!`);
                } else {
                    renderAlert("error", `Tivemos um problema para verificar o codigo envidado, tente novamente!`);
                }
            } else {
                renderAlert(
                    "error",
                    `Tivemos um problema com a conexão com o nosso servidor, por favor tente mais tarde`
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    const renderAlert = (type: AlertType, text: string) => {
        setTextAlert(text);
        setAlertType(type);
        setDisplayAlert("flex");
    };

    return (
        <div style={styles.container}>
            <PageLoading open={isLoading} />
            {verified && <Navigate to="" />}
            <div style={styles.containerContent}>
                <p>
                    <b>Digite o Codigo que Foi enviado para o seu email!</b>
                </p>
                <TextField
                    style={styles.input}
                    fullWidth
                    margin="normal"
                    required
                    id="code"
                    label="Codigo enviado"
                    name="code"
                    autoComplete="code"
                    autoFocus
                    value={code}
                    onChange={(event) => {
                        setCode(event.target.value);
                    }}
                    inputProps={{
                        "data-test-id": "input-email",
                    }}
                />
                <Button
                    style={styles.button}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={send}
                    data-testid="button-login"
                >
                    VERIFICAR
                </Button>
                <Button
                    style={styles.button}
                    fullWidth
                    variant="contained"
                    sx={{ mb: 2 }}
                    color="error"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    VOLTAR
                </Button>
                <p>Caso não receba o codigo, volte a a pagina anterior e solicite o codigo novamente!</p>
                <p>Mas antes, olha a caixa de spam do seu email!</p>
                <Alert style={styles.alert} sx={{ display: displayAlert }} severity={alertType}>
                    {textAlert}
                </Alert>
            </div>
        </div>
    );
}
