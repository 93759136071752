import { type PropsWithChildren } from "react";

import AlertContextProvider from "./Alert/alert";
import SecurityContextProvider from "./Security";
import StoreDataContextProvider from "./StoreData";
import ProductCategoriesContextProvider from "./ProductCategories";
import ProductsContextProvider from "./Products";
import InvoiceContextProvider from "./Invoices";
import InvoiceItemsContextProvider from "./InvoiceItems";
import StoreAddressesContextProvider from "./StoreAddresses";
import TelephonesContextProvider from "./Telephones";
import StoreSettingsContextProvider from "./storeSettings";

export default function GlobalContext({ children }: PropsWithChildren<unknown>) {
    return (
        <AlertContextProvider>
            <SecurityContextProvider>
                <StoreDataContextProvider>
                    <StoreAddressesContextProvider>
                        <StoreSettingsContextProvider>
                            <TelephonesContextProvider>
                                <ProductCategoriesContextProvider>
                                    <ProductsContextProvider>
                                        <InvoiceContextProvider>
                                            <InvoiceItemsContextProvider>{children}</InvoiceItemsContextProvider>
                                        </InvoiceContextProvider>
                                    </ProductsContextProvider>
                                </ProductCategoriesContextProvider>
                            </TelephonesContextProvider>
                        </StoreSettingsContextProvider>
                    </StoreAddressesContextProvider>
                </StoreDataContextProvider>
            </SecurityContextProvider>
        </AlertContextProvider>
    );
}
