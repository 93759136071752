import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useReactToPrint } from "react-to-print";

import { type IInvoice } from "../../../../../commons/interfaces";
import "./index.css";
import TableForPrint from "../../../../components/TableForPrint";
import { useStoreData } from "../../../../../contexts/StoreData";

interface IProps {
    invoice?: IInvoice;
    open: boolean;
    setModalOpen: (value: boolean) => void;
}

interface IRow {
    item: string;
    QT: number;
    "V. Unit.": number;
    "V. Total": number;
}

export default function ModalPrintInvoice(props: IProps) {
    const [marginTop, setMarginTop] = useState(0);
    const [rowsToPrint, setRowsToPrint] = useState<IRow[]>([]);
    const { invoice, open, setModalOpen } = props;

    const componentRef = useRef(null);

    const handleClose = () => {
        setModalOpen(false);
    };

    const { store } = useStoreData();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            handleClose();
        },
    });

    useEffect(() => {
        if (open) {
            setMarginTop(80);
        } else setMarginTop(0);
    }, [open]);

    useEffect(() => {
        const styleComponent = document.getElementById("print-style");
        if (styleComponent) {
            styleComponent.innerText = `@page {
                size: 80mm 200mm;
            }`;
        }
    }, []);

    useEffect(() => {
        if (invoice) {
            const newRows: IRow[] = invoice.invoiceItems.map((item) => ({
                item: item.productName,
                QT: item.quantity,
                "V. Unit.": item.unityPrice,
                "V. Total": item.totalPrice,
            }));

            if (invoice?.closingDatetime && store?.settings) {
                const settings = store.settings;
                const { chargeServiceFee, feeName, feeAmount } = settings.serviceFee;

                if (chargeServiceFee && feeName && feeAmount) {
                    newRows.push({
                        item: feeName,
                        QT: 1,
                        "V. Unit.": feeAmount,
                        "V. Total": feeAmount,
                    });
                }
            }
            setRowsToPrint(newRows);
        }
    }, [invoice]);

    return (
        <Modal open={open} onClose={handleClose} style={{ marginTop }}>
            <div className="modal-print-invoice-container" ref={componentRef}>
                <div className="modal-print-invoice-container-title">
                    <h1>{`Conta N° ${invoice?.id || ""}`}</h1>
                </div>
                <div className="modal-print-invoice-container-content">
                    <div className="modal-print-invoice-container-locale">
                        <span>
                            <b>Local</b>
                        </span>
                        <span>{`Mesa ${invoice?.tableName || "0"}`}</span>
                    </div>
                    <TableForPrint rows={rowsToPrint} />
                </div>
                <div className="modal-print-invoice-total-price">{`Total: R$${invoice?.totalPrice}`}</div>
                <div className="modal-print-invoice-container-actions">
                    <Button variant="contained" onClick={handlePrint}>
                        IMPRIMIR
                    </Button>
                    <Button variant="contained" color="error" onClick={handleClose}>
                        FECHAR
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
