import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface IProps {
    labelText: string;
    value: Date | undefined | null;
    setValue: (value: Date | null) => void;
    maxDate?: Date | undefined;
    minDate?: Date | undefined;
}

export default function FilterDate(props: IProps) {
    const { labelText, value, setValue, maxDate, minDate } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={labelText}
                onChange={(event) => {
                    setValue(event);
                }}
                value={value}
                format="dd/MM/yyyy"
                maxDate={maxDate}
                minDate={minDate}
            />
        </LocalizationProvider>
    );
}
