import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import "./index.css";

interface IProps {
    text: string;
    modalOpen: boolean;
    setModalOpen: (value: boolean) => void;
    action?: () => void;
}

export default function ModalAlert(props: IProps) {
    const [marginTop, setMarginTop] = useState(0);

    const { text, modalOpen, setModalOpen, action } = props;

    const handleClose = () => {
        if (action) {
            action();
        }
        setModalOpen(false);
    };

    useEffect(() => {
        if (modalOpen) {
            setMarginTop(200);
        } else setMarginTop(0);
    }, [modalOpen]);

    return (
        <Modal open={modalOpen} onClose={handleClose}>
            <div className="modal-alert-container" style={{ marginTop }}>
                <div className="modal-alert-title">
                    <p>{text}</p>
                </div>
                <Divider style={{ marginTop: 10 }} />
                <div className="modal-alert-actions">
                    <Button onClick={handleClose} variant="contained" color={action ? "primary" : "error"}>
                        OK
                    </Button>
                    {action && (
                        <>
                            <Button
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                variant="contained"
                                color="error"
                            >
                                CANCELAR
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
}
