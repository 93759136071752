import { useState, useEffect, type FormEvent } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import "./index.css";
import { findCep } from "../../../../integrations/buscaCep";
import { useStoreAddress } from "../../../../contexts/StoreAddresses";
import { type IStore } from "../../../../commons/interfaces";

interface IProps {
    store: IStore | undefined;
}

export default function StoreAddressData(props: IProps) {
    const [isEdit, setIsEdit] = useState(false);
    const [street, setStreet] = useState("");
    const [cep, setCep] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [number, setNumber] = useState<number | undefined>();
    const [complement, setComplement] = useState<string | undefined>();

    const { createStoreAddress, updateStoreAddress, storeAddress } = useStoreAddress();
    const { store } = props;

    const reset = () => {
        setStreet("");
        setIsEdit(false);
    };

    const save = async () => {
        if (store) {
            if (!storeAddress) {
                await createStoreAddress(store.id, {
                    cep,
                    street,
                    district,
                    city,
                    complement,
                    number,
                    state,
                    country: "BRASIL",
                });
            } else {
                await updateStoreAddress(storeAddress.id, {
                    cep,
                    street,
                    district,
                    city,
                    complement,
                    number,
                    state,
                });
            }
            setIsEdit(false);
        }
    };

    useEffect(() => {
        if (storeAddress) {
            setCep(storeAddress.cep);
            setStreet(storeAddress.street);
            setNumber(storeAddress.number || undefined);
            setComplement(storeAddress.complement || "");
            setDistrict(storeAddress.district);
            setCity(storeAddress.city);
            setState(storeAddress.state);
        }
    }, [storeAddress]);

    useEffect(() => {
        const find = async () => {
            try {
                const response = await findCep(cep.replace("-", ""));
                if (response) {
                    setStreet(response.data.logradouro);
                    setDistrict(response.data.bairro);
                    setCity(response.data.localidade);
                    setState(response.data.uf);
                }
            } catch (error) {}
        };

        if (cep.length >= 8) {
            find();
        }
    }, [cep]);
    return (
        <div className="store-address-data-edit-container">
            {!isEdit ? (
                <div className="store-address-data-card">
                    {!storeAddress ? (
                        <h2 className="store-address-title-info">Nenhum endereço cadastrado</h2>
                    ) : (
                        <>
                            <h2 className="store-address-title-info">Endereço</h2>
                            <div className="store-address-data-card-item">
                                <span>
                                    <b>CEP</b>
                                </span>
                                <span>{storeAddress.cep}</span>
                            </div>
                            <div className="store-address-data-card-item">
                                <span>
                                    <b>Rua ou Logradouro</b>
                                </span>
                                <span>{storeAddress.street}</span>
                            </div>
                            <div className="store-address-data-card-item">
                                <span>
                                    <b>Bairro</b>
                                </span>
                                <span>{storeAddress.district}</span>
                            </div>
                            <div className="store-address-data-card-item">
                                <span>
                                    <b>Cidade</b>
                                </span>
                                <span>{storeAddress.city}</span>
                            </div>
                            <div className="store-address-data-card-item">
                                <span>
                                    <b>Estado</b>
                                </span>
                                <span>{storeAddress.state}</span>
                            </div>
                        </>
                    )}
                    <div className="store-address-card-actions">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        >
                            EDITAR
                        </Button>
                    </div>
                </div>
            ) : (
                <Box
                    component="form"
                    onSubmit={(e: FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        save();
                    }}
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="cep"
                                    label="CEP"
                                    name="cep"
                                    value={cep}
                                    onChange={(event) => {
                                        setCep(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="street"
                                    label="Nome da rua ou Logradouro"
                                    name="street"
                                    value={street}
                                    onChange={(event) => {
                                        setStreet(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="number"
                                    label="Numero"
                                    name="number"
                                    type="number"
                                    value={number}
                                    onChange={(event) => {
                                        setNumber(parseInt(event.target.value));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="complement"
                                    label="Complemento"
                                    name="complement"
                                    value={complement}
                                    onChange={(event) => {
                                        setComplement(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="district"
                                    label="Bairro"
                                    name="district"
                                    value={district}
                                    onChange={(event) => {
                                        setDistrict(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="city"
                                    label="Cidade"
                                    name="city"
                                    value={city}
                                    onChange={(event) => {
                                        setCity(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="state"
                                    label="Estado"
                                    name="state"
                                    value={state}
                                    onChange={(event) => {
                                        setState(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} type="submit">
                            {"SALVAR"}
                        </Button>
                        <Button fullWidth variant="contained" color="error" sx={{ mb: 2 }} onClick={reset}>
                            {"CANCELAR"}
                        </Button>
                    </Box>
                </Box>
            )}
        </div>
    );
}
