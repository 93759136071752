import { type IInvoice } from "../commons/interfaces";
import { removeNullAndUndefinedFields } from "../commons/utils";
import { foobleAPI } from "./baseApi";

interface ICrateInvoiceRequest {
    storeId: number;
    tableName: string;
}

interface ICreateInvoiceResponse {
    id: number;
}

export interface IUpdateInvoiceRequest {
    tableName?: string;
    closingDatetime?: string;
    totalPrice?: number;
    feeService?: number;
    reOpenInvoice?: boolean;
}

export interface IIlistInvoiceParams {
    orderBy: "ASC" | "DESC";
    status: "OPEN" | "CLOSED" | "ALL";
    endDate?: string;
    startDate?: string;
    orderId?: number;
    tableNumber?: string;
    limit?: number;
    page?: number;
}

export async function findInvoiceByTableName(storeId: number, tableName: number) {
    return foobleAPI.get<IInvoice>(`/invoices/store/${storeId}/table/${tableName}`);
}

export async function findInvoiceById(invoiceId: number) {
    return foobleAPI.get<IInvoice>(`/invoices/${invoiceId}`);
}

export async function createInvoice(data: ICrateInvoiceRequest) {
    return foobleAPI.post<ICreateInvoiceResponse>(`/invoices`, data);
}

export async function updateInvoice(invoiceId: number, data: IUpdateInvoiceRequest) {
    return foobleAPI.put(`/invoices/${invoiceId}`, data);
}

export async function listInvoiceByStoreId(storeId: number, params: IIlistInvoiceParams) {
    const paramsFormatted = removeNullAndUndefinedFields(params);
    return foobleAPI.get<IInvoice[]>(`/invoices/stores/${storeId}`, { params: paramsFormatted });
}

export async function closeInvoice(invoiceId: number) {
    return foobleAPI.put(`/invoices/close-invoice/${invoiceId}`, { closingDatetime: new Date().toISOString() });
}
