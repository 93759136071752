import { useState, type CSSProperties } from "react";
import { isAxiosError } from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import PageLoading from "../../components/PageLoading";
import { passwordRecoveryRequest } from "../../../integrations/users";
import { PASSWORD_RECOVERY_URL } from "../../../config";
import { useNavigate } from "react-router-dom";

type AlertType = "error" | "success" | "warning" | "info";
type displayAlertType = "none" | "flex";

interface IStyles {
    container: CSSProperties;
    containerContent: CSSProperties;
    input: CSSProperties;
    textInfoTop: CSSProperties;
    button: CSSProperties;
    alert: CSSProperties;
}

const styles: IStyles = {
    container: {
        width: "100%",
        height: "100vh",
        padding: "50px 20px",
        fontFamily: "Roboto, sans-serif",
        display: "flex",
        flexDirection: "row",

        justifyContent: "center",
        boxSizing: "border-box",
    },
    containerContent: {
        width: "100%",
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    input: {
        width: "100%",
        maxWidth: "400px",
    },
    textInfoTop: {
        fontWeight: "300",
    },
    button: {
        width: "100%",
        maxWidth: "400px",
    },
    alert: {
        width: "100%",
    },
};

export default function PasswordRecoveryRequest() {
    const [email, setEmail] = useState("");
    const [textAlert, setTextAlert] = useState<string>("");
    const [alertType, setAlertType] = useState<AlertType>("error");
    const [displayAlert, setDisplayAlert] = useState<displayAlertType>("none");

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const send = async () => {
        try {
            setIsLoading(true);
            const response = await passwordRecoveryRequest({
                email,
                loginPageUrl: PASSWORD_RECOVERY_URL || "",
            });
            if (response.status < 400) {
                const textMessage = `O email foi enviado com sucessso para o endereço ${email}, verifique tambem a sua caixa de spam`;
                renderAlert("success", textMessage);
            }
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (error.response.status === 404) {
                    renderAlert("error", `O email ${email} não esta cadastrado em nossa base de dados!`);
                } else {
                    renderAlert(
                        "error",
                        `Tivemos um problema para realizar a solicitação, por favor tente mais tarde.`
                    );
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const renderAlert = (type: AlertType, text: string) => {
        setTextAlert(text);
        setAlertType(type);
        setDisplayAlert("flex");
    };

    return (
        <div style={styles.container}>
            <PageLoading open={isLoading} />
            <div style={styles.containerContent}>
                <p style={styles.textInfoTop}>
                    Caso o seu endereço de email esteja cadastrado em nossa base de dados, enviaremos um email com uma
                    url para você redefinir a sua senha
                </p>
                <p>
                    <b>O Link sera Valido por apenas 5 minutos</b>
                </p>
                <TextField
                    style={styles.input}
                    fullWidth
                    margin="normal"
                    required
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                    inputProps={{
                        "data-test-id": "input-email",
                    }}
                />
                <Button
                    style={styles.button}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={async () => send()}
                >
                    ENVIAR
                </Button>
                <Button
                    style={styles.button}
                    fullWidth
                    variant="contained"
                    sx={{ mb: 2 }}
                    onClick={async () => {
                        navigate(-1);
                    }}
                    color="error"
                >
                    VOLTAR
                </Button>
                <Alert style={styles.alert} sx={{ display: displayAlert }} severity={alertType}>
                    {textAlert}
                </Alert>
            </div>
        </div>
    );
}
