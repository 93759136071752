import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import TokenManager from "../commons/TokenManager";
import Auth from "./Auth";
import Register from "./Register";
import RegisterStore from "./RegisterStore";
import Dashboard from "./Dashboard";
import TableStore from "./tableStore";
import PasswordRecoveryRequest from "./Auth/PasswordRecoveryRequest";
import PasswordRecovery from "./Auth/PasswordRecovery";
import ValidateEmailRequest from "./Auth/ValidateEmailRequest";
import ValidateEmail from "./Auth/ValidateEmail";
import PageNotFound from "./components/PageNotFound";
import LandingPage from "./LandingPage";

export default function Pages() {
    const validatedPaths = ["/validate/email/request", "/validate/email", "register-store"];
    const createPrivateElement = (element: JSX.Element) => {
        const accessToken = TokenManager.getAccessToken();
        if (!accessToken) return <Navigate to={"/login"} />;
        else {
            const { validated } = TokenManager.getTokenData();
            if (!validated && !validatedPaths.includes(window.location.pathname))
                return <Navigate to={"/validate/email/request"} />;
        }
        return element;
    };

    const redirectIfIsAuthenticated = (element: JSX.Element) => {
        const accessToken = TokenManager.getAccessToken();
        if (accessToken) {
            const { validated } = TokenManager.getTokenData();
            if (!validated && !validatedPaths.includes(window.location.pathname)) {
                return <Navigate to={"/validate/email/request"} />;
            }
            return <Navigate to={"/products"} />;
        }
        return element;
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route element={redirectIfIsAuthenticated(<LandingPage />)} path="" />
                <Route element={createPrivateElement(<Dashboard />)} path="/*" />
                <Route element={createPrivateElement(<RegisterStore />)} path="/register-store" />
                <Route element={<Register />} path="/register" />
                <Route element={<Auth />} path="/login" />
                <Route element={<TableStore />} path="/table-store/:tableParams/*" />
                <Route element={<PasswordRecoveryRequest />} path="/password/recovery/request" />
                <Route element={<PasswordRecovery />} path="/password/recovery" />
                <Route element={createPrivateElement(<ValidateEmailRequest />)} path="/validate/email/request" />
                <Route element={createPrivateElement(<ValidateEmail />)} path="/validate/email" />
                <Route element={<PageNotFound />} path="*" />
            </Routes>
        </BrowserRouter>
    );
}
