import { useStoreData } from "../../../contexts/StoreData";
import "./index.css";
import StoreAddressData from "./FormAddress";
import FormStoreData from "./FormStoreData";
import FormTelephones from "./FormTelephones";
import NavBar from "../components/navBar";
import Footer from "../../components/Footer";

export default function Profile() {
    const { store } = useStoreData();

    return (
        <>
            <NavBar />
            <div className="profile-container">
                <FormStoreData store={store} />
                <StoreAddressData store={store} />
                <FormTelephones store={store} />
            </div>
            <Footer />
        </>
    );
}
