import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { useInvoices } from "../../../contexts/Invoices";
import { type PrintStatusType, type IInvoice } from "../../../commons/interfaces";
import Table from "../../components/Table";
import "./index.css";
import FilterDate from "../../components/FilterDate";
import { useStoreData } from "../../../contexts/StoreData";
import NavBar from "../components/navBar";
import AdsAutomatic from "../../components/AdsGoogle/Automatic";

interface IRows {
    "N° da conta": number;
    Mesa: string;
    Total: number;
    status: "ABERTO" | "FECHADO";
    "Data Abertura": string;
    "": JSX.Element;
    color?: string;
    mensagem?: string;
}

interface IFilters {
    status: "OPEN" | "CLOSED" | "ALL";
    orderBy: "ASC" | "DESC";
    tableNumber?: number | string;
    orderId?: number | string;
    startDate?: Date | null;
    endDate?: Date | null;
}

const initialValue: IFilters = {
    status: "OPEN",
    orderBy: "DESC",
    orderId: "",
    tableNumber: "",
    startDate: null,
    endDate: null,
};

export default function Orders() {
    const [invoices, setInvoices] = useState<IInvoice[]>([]);
    const [rows, setRows] = useState<IRows[]>([]);
    const [filters, setFilters] = useState<IFilters>(initialValue);
    const { listInvoicesByStoreId, invoicesFromStore } = useInvoices();
    const { store } = useStoreData();

    useEffect(() => {
        setInvoices(invoicesFromStore);
    }, [invoicesFromStore]);

    const handleChangeFilters = (filter: keyof IFilters, value: string | Date | number | undefined | null) => {
        setFilters({ ...filters, [filter]: value });
    };

    const clearFilters = () => {
        setFilters(initialValue);
    };

    useEffect(() => {
        const rowsData: IRows[] = invoices.map((invoice) => {
            const pendingPrints = invoice.invoiceItems.filter((i) => i.printStatus !== "PRINTED");
            let printStatus: PrintStatusType | undefined;

            if (pendingPrints.length > 0) {
                const hasError = pendingPrints.filter((i) => i.printStatus === "ERROR");
                if (hasError.length > 0) printStatus = "ERROR";
                const hasPending = pendingPrints.filter((i) => i.printStatus === "PENDING");
                if (hasPending.length > 0) printStatus = "PENDING";
            }
            return {
                "N° da conta": invoice.id,
                "Data Abertura": format(new Date(invoice.createdAt), "dd/MM/yyyy HH:mm:ss"),
                Mesa: invoice.tableName,
                Total: invoice.totalPrice,
                status: invoice.closingDatetime ? "FECHADO" : "ABERTO",
                "": (
                    <Link to={`/orders/${invoice.id}`}>
                        <Button variant="contained" color="primary">
                            VER
                        </Button>
                    </Link>
                ),
                mensagem: printStatus ? (printStatus === "PENDING" ? "Novo Pedido" : "Falha na Impressão") : undefined,
                color: printStatus ? (printStatus === "PENDING" ? "yellow" : "red") : undefined,
            };
        });
        console.log(invoices[0]);

        setRows(rowsData);
    }, [invoices]);

    const find = async () => {
        const startDate = filters.startDate ? format(filters.startDate, "yyyy-MM-dd") : "";
        const endDate = filters.endDate ? format(filters.endDate, "yyyy-MM-dd") : "";
        if (store) {
            await listInvoicesByStoreId(store.id, {
                orderBy: filters.orderBy,
                status: filters.status,
                endDate,
                startDate,
                orderId: filters.orderId ? parseInt(filters.orderId.toString()) : undefined,
                tableNumber: filters.tableNumber ? filters.tableNumber.toString() : undefined,
            });
        }
    };

    useEffect(() => {
        find();
    }, [store]);

    return (
        <div className="orders-container">
            <AdsAutomatic />
            <NavBar />
            <div className="orders-filters">
                <FormControl sx={{ width: "300px" }}>
                    <InputLabel id="filter-status-select-label">Status</InputLabel>
                    <Select
                        labelId="filter-status-select-label"
                        id="status-select-filter"
                        value={filters.status}
                        label="Status"
                        onChange={(event) => {
                            handleChangeFilters("status", event.target.value);
                        }}
                    >
                        <MenuItem value={"OPEN"}>ABERTO</MenuItem>
                        <MenuItem value={"CLOSED"}>FECHADO</MenuItem>
                        <MenuItem value={"ALL"}>TODOS</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ width: "300px" }}>
                    <InputLabel id="filter-order-select-label">Ordenar</InputLabel>
                    <Select
                        labelId="filter-order-select-label"
                        id="order-select-filter"
                        value={filters.orderBy}
                        label="Ordenar"
                        onChange={(event) => {
                            handleChangeFilters("orderBy", event.target.value);
                        }}
                    >
                        <MenuItem value={"ASC"}>MAIS ANTIGO</MenuItem>
                        <MenuItem value={"DESC"}>MAIS RECENTE</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="filter-table-number"
                    type="number"
                    label="N° da Mesa"
                    name="filterTableNumber"
                    value={filters.tableNumber}
                    onChange={(event) => {
                        handleChangeFilters("tableNumber", event.target.value);
                    }}
                />
                <TextField
                    id="filter-order-id"
                    type="number"
                    label="N° da conta"
                    name="filterOrderId"
                    value={filters.orderId}
                    onChange={(event) => {
                        handleChangeFilters("orderId", event.target.value);
                    }}
                />
                <FilterDate
                    labelText="Data Inicial"
                    setValue={(value) => {
                        handleChangeFilters("startDate", value);
                    }}
                    value={filters.startDate}
                    maxDate={filters.endDate ? filters.endDate : undefined}
                />
                <FilterDate
                    labelText="Data Final"
                    setValue={(value) => {
                        handleChangeFilters("endDate", value);
                    }}
                    value={filters.endDate}
                    minDate={filters.startDate ? filters.startDate : undefined}
                />
            </div>
            <div className="order-filters-actions">
                <Button variant="contained" color="primary" onClick={clearFilters}>
                    LIMPAR FILTROS
                </Button>
                <Button variant="contained" color="primary" onClick={find}>
                    BUSCAR
                </Button>
            </div>
            <div className="order-items-list">
                <Table rows={rows} />
            </div>
        </div>
    );
}
