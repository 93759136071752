import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useReactToPrint } from "react-to-print";

import { type IInvoice, type IInvoiceItem } from "../../../../../commons/interfaces";
import "./index.css";

interface IProps {
    item?: IInvoiceItem;
    invoice?: IInvoice;
    open: boolean;
    setModalOpen: (value: boolean) => void;
    markAsPrinted: (invoiceItemId: number) => Promise<void>;
}

export default function ModalPrint(props: IProps) {
    const [marginTop, setMarginTop] = useState(0);
    const { item, invoice, open, setModalOpen, markAsPrinted } = props;
    const componentRef = useRef(null);

    const handleClose = () => {
        setModalOpen(false);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: async () => {
            item && (await markAsPrinted(item.id));
            handleClose();
        },
    });

    useEffect(() => {
        if (open) {
            setMarginTop(200);
        } else setMarginTop(0);
    }, [open]);

    useEffect(() => {
        const styleComponent = document.getElementById("print-style");
        if (styleComponent) {
            styleComponent.innerText = `@page {
                    size: 80mm 150mm;
                }`;
        }
    }, []);

    return (
        <Modal open={open} onClose={handleClose} style={{ marginTop }}>
            <div className="modal-print-order-container">
                <div className="modal-print-order-container-title">
                    <h1>{item?.productName || ""}</h1>
                </div>
                <div className="modal-print-order-container-content" ref={componentRef}>
                    <div className="modal-print-order-container-content-item">
                        <span>
                            <b>Local</b>
                        </span>
                        <span>{`Mesa ${invoice?.tableName || "0"}`}</span>
                    </div>
                    <div className="modal-print-order-container-content-item">
                        <span>
                            <b>Item:</b>
                        </span>
                        <span>{item?.productName}</span>
                    </div>
                    <div className="modal-print-order-container-content-item">
                        <span>
                            <b>Quantidade:</b>
                        </span>
                        <span>{item?.quantity}</span>
                    </div>
                    <div className="modal-print-order-container-content-item">
                        <span>
                            <b>Observações:</b>
                        </span>
                        <span>{item?.observation || ""}</span>
                    </div>
                    <div className="modal-print-order-container-content-item">
                        <span>
                            <b>V. Unitario: </b>
                        </span>
                        <span>{`R$ ${item?.unityPrice || "0"}`}</span>
                    </div>
                    <div className="modal-print-order-container-content-item">
                        <span>
                            <b>V. Total:</b>
                        </span>
                        <span>{`R$ ${item?.totalPrice || "0"}`}</span>
                    </div>
                </div>
                <div className="modal-print-order-container-actions">
                    <Button variant="contained" onClick={handlePrint}>
                        IMPRIMIR
                    </Button>
                    <Button variant="contained" color="error" onClick={handleClose}>
                        FECHAR
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
