import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import QRCode from "react-qr-code";

import LogoImg from "../../static/images/SuaPageLogo.png";
import CustomerImg from "../../static/marketing/curstomer_1.jpg";
import CustomerImg3 from "../../static/marketing/customer_3.jpg";
import DeliveryImg from "../../static/marketing/delivery_1.jpg";
import WaiterImg from "../../static/marketing/waiter_1.jpg";
import CustomerQr from "../../static/marketing/customer_qr_1.jpg";
import CustomerQr2 from "../../static/marketing/customer_qr_2.jpg";

import "./index.css";
import Footer from "../components/Footer";
import TableParamsManager from "../../commons/TableParamsManager";
export default function LandingPage() {
    const currentDate = new Date();
    const index = currentDate.getTime() / 1000;
    const tableParams = TableParamsManager.encode(2, index);
    const urlStore = `https://suapage.com.br/table-store/${tableParams}/products`;
    return (
        <div className="landing-page-container">
            <div className="landing-page-nav">
                <div className="landing-page-nav-title">
                    <img src={LogoImg} alt="Sua Page" />
                    <h1>Sua Page</h1>
                </div>
                <div className="landing-page-nav-actions">
                    <Link to={"/register"}>
                        <Button variant="contained">Cadastrar</Button>
                    </Link>
                    <Link to={"/login"}>
                        <Button variant="contained">Login</Button>
                    </Link>
                </div>
            </div>
            <div className="landing-page-content">
                <div className="landing-page-content-info">
                    <div className="landing-page-content-info-main">
                        <h1>Praticidade para os seus funcionarios e para os seus clientes</h1>
                    </div>
                    <div className="landing-page-content-info-content">
                        <h2>
                            Com o Sistema da Sua Page, seu restaurante está pronto para oferecer uma experiência de
                            excelência. Desde a gestão interna até o atendimento ao cliente, nossa plataforma
                            proporciona eficiência e comodidade em todos os aspectos do seu negócio. Simplifique
                            processos, aumente a satisfação dos funcionários e encante seus clientes com a praticidade
                            que só o nossos Sistema pode oferecer.
                        </h2>
                    </div>
                    <div className="landing-page-content-qr">
                        <h2>Veja Como os Clientes sentados na mesa verão sua Loja:</h2>
                        <p>Escaneie o QR Code Abaixo ou clique no Botão</p>
                        <QRCode value={urlStore} />

                        <Link to={urlStore} target="_blank">
                            <Button variant="contained">Acessar QR Code</Button>
                        </Link>
                    </div>
                </div>
                <div className="landing-page-content-images">
                    <img src={CustomerQr} alt="customer-restaurant" />
                    <img src={CustomerImg} alt="customer-restaurant" />
                    <img src={DeliveryImg} alt="delivery-restaurant" />
                    <img src={WaiterImg} alt="waiter-restaurant" />
                    <img src={CustomerImg3} alt="customer-restaurant" />
                    <img src={CustomerQr2} alt="customer-restaurant" />
                </div>
            </div>
            <Footer />
        </div>
    );
}
