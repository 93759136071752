import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import "./index.css";

interface IProps {
    productName: string;
    quantity: number;
    productPrice: number;
    action: () => void | Promise<void>;
    observation: string;
    modalOpen: boolean;
    setModalOpen: (value: boolean) => void;
}

export default function ModalConfirmationOrder(props: IProps) {
    const [marginTop, setMarginTop] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const { action, modalOpen, setModalOpen, productName, quantity, observation, productPrice } = props;

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleAction = async () => {
        setIsLoading(true);
        try {
            await action();
        } catch (error) {}

        setIsLoading(false);
    };

    useEffect(() => {
        if (modalOpen) {
            setMarginTop(200);
        } else setMarginTop(0);
    }, [modalOpen]);

    return (
        <Modal open={modalOpen} onClose={handleClose}>
            <div className="modal-confirmation-container" style={{ marginTop }}>
                <div className="modal-confirmation-title">
                    <p>Confirme o seu Pedido!</p>
                </div>
                <Divider style={{ marginTop: 10 }} />
                <div className="modal-confirmation-content">
                    <p>
                        <b>Item:</b> {productName}
                    </p>
                    <p>
                        <b>Quantidade:</b> {quantity}
                    </p>
                    <p>
                        <b>Observação:</b> {observation}
                    </p>
                    <div className="modal-confirmation-total-price">
                        <span>Total:</span>
                        <span>{`R$ ${parseFloat((quantity * productPrice).toFixed(2)).toLocaleString()}`}</span>
                    </div>
                </div>
                <Divider style={{ marginTop: 10 }} />
                <div className="modal-confirmation-actions">
                    <Button onClick={handleAction} variant="contained" disabled={isLoading}>
                        PEDIR
                    </Button>
                    <Button onClick={handleClose} variant="contained" disabled={isLoading} color="error">
                        CANCELAR
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
