import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./index.css";

type IRows = Record<any, any>;

interface IProps {
    rows: IRows[];
}

export default function TableForPrint(props: IProps) {
    const { rows } = props;

    const keys = Object.keys(rows[0] || []);
    return (
        <TableContainer
            component={Paper}
            sx={{ minWidth: "300px", width: { sm: "320px", md: "max-content" }, marginTop: "10px" }}
            className="table-for-print-container"
        >
            <Table className="table-for-print-container-table ">
                <TableHead className="table-for-print-container-head">
                    <TableRow className="table-for-print-container-row ">
                        {keys.map((key, index) => {
                            if (index === 0) {
                                return (
                                    <TableCell className="table-for-print-container-cell" key={`key-${key}`}>
                                        {key}
                                    </TableCell>
                                );
                            }
                            return (
                                <TableCell className="table-for-print-container-cell" key={`key-${key}`}>
                                    {key}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody sx={{ maxWidth: "80mm" }}>
                    {rows.map((row, i) => (
                        <TableRow
                            className="table-for-print-container-row"
                            key={`row-${i}`}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            {keys.map((key, index) => {
                                if (index === 0) {
                                    return (
                                        <TableCell
                                            className="table-for-print-container-cell"
                                            key={`cell-${key}-${index}`}
                                            component="th"
                                            scope="row"
                                        >
                                            {row[key]}
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell className="table-for-print-container-cell" key={`cell-${key}-${index}`}>
                                        {row[key]}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
