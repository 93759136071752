import { useState, type CSSProperties } from "react";
import { isAxiosError } from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { Navigate } from "react-router-dom";

import PageLoading from "../../components/PageLoading";
import { validateEmailRequest } from "../../../integrations/users";

type AlertType = "error" | "success" | "warning" | "info";
type displayAlertType = "none" | "flex";

interface IStyles {
    container: CSSProperties;
    containerContent: CSSProperties;
    textInfoTop: CSSProperties;
    button: CSSProperties;
    alert: CSSProperties;
}

const styles: IStyles = {
    container: {
        width: "100%",
        height: "100vh",
        padding: "50px 20px",
        fontFamily: "Roboto, sans-serif",
        display: "flex",
        flexDirection: "row",

        justifyContent: "center",
        boxSizing: "border-box",
    },
    containerContent: {
        width: "100%",
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    textInfoTop: {
        fontWeight: "300",
        margin: "15px",
    },
    button: {
        width: "100%",
        maxWidth: "400px",
    },
    alert: {
        width: "100%",
    },
};

export default function ValidateEmailRequest() {
    const [textAlert, setTextAlert] = useState<string>("");
    const [alertType, setAlertType] = useState<AlertType>("error");
    const [displayAlert, setDisplayAlert] = useState<displayAlertType>("none");

    const [isLoading, setIsLoading] = useState(false);
    const [emailSended, setEmailSended] = useState(false);

    const send = async () => {
        try {
            setIsLoading(true);
            const response = await validateEmailRequest();
            if (response.status < 400) {
                setEmailSended(true);
            }
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (error.response.status === 401) {
                    renderAlert(
                        "error",
                        `Por falor, faça o login novamente, e tente outra vez!`
                    );
                } else {
                    renderAlert(
                        "error",
                        `Tivemos um problema para realizar a solicitação, por favor tente mais tarde.`
                    );
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const renderAlert = (type: AlertType, text: string) => {
        setTextAlert(text);
        setAlertType(type);
        setDisplayAlert("flex");
    };

    return (
        <div style={styles.container}>
            {emailSended && <Navigate to="/validate/email" />}
            <PageLoading open={isLoading} />
            <div style={styles.containerContent}>
                <h3>Precisamos validar seu email</h3>
                <p style={styles.textInfoTop}>
                    Enviaremos um email com um codigo para o endereço de email
                    que você cadastrou!
                </p>

                <Button
                    style={styles.button}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={send}
                    data-testid="button-login"
                >
                    ENVIAR CODIGO
                </Button>
                <Alert
                    style={styles.alert}
                    sx={{ display: displayAlert }}
                    severity={alertType}
                >
                    {textAlert}
                </Alert>
            </div>
        </div>
    );
}
