import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CategoryIcon from "@mui/icons-material/Category";
import DescriptionIcon from "@mui/icons-material/Description";
import BallotIcon from "@mui/icons-material/Ballot";

import LinkDashboardMenu from "../../../components/linkDashboardMenu";

import defaultImage from "../../../../static/images/semImagem.png";

import { useStoreData } from "../../../../contexts/StoreData";
import { useProductCategories } from "../../../../contexts/ProductCategories";
import TableParamsManager from "../../../../commons/TableParamsManager";

interface Props {
    window?: () => Window;
    mobileOpen: boolean;
    handleDrawerToggle: () => void;
    drawerWidth: number;
    tableParams: string;
}

export default function Menu(props: Props) {
    const { window, mobileOpen, handleDrawerToggle, drawerWidth, tableParams } = props;

    const { pathname } = useLocation();
    const { store, readStore } = useStoreData();
    const { productCategories } = useProductCategories();

    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        if (!store) {
            const { storeId } = TableParamsManager.decode(tableParams);
            readStore(storeId);
        }
    }, []);

    useEffect(() => {
        if (mobileOpen) {
            handleDrawerToggle();
        }
    }, [pathname]);

    const menu = (
        <div>
            <CardMedia
                component="img"
                image={store?.imageUrl || defaultImage}
                alt="storeImageMenu"
                sx={{ width: 151, margin: "auto" }}
            />
            <Divider />
            <List>
                <LinkDashboardMenu
                    to={`/table-store/${tableParams}/invoice`}
                    text="Pedidos"
                    icon={<DescriptionIcon />}
                />
            </List>
            <Divider />
            <List>
                <LinkDashboardMenu to={`/table-store/${tableParams}/products`} text="Todos" icon={<BallotIcon />} />
                {productCategories.map((category) => (
                    <LinkDashboardMenu
                        key={`category-${category.id}`}
                        to={`/table-store/${tableParams}/products/category/${category.id}`}
                        text={category.name}
                        icon={<CategoryIcon />}
                    />
                ))}
            </List>
        </div>
    );

    return (
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: "block",
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
            >
                {menu}
            </Drawer>
        </Box>
    );
}
