import { useState, type ChangeEvent } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";

import InputSearch from "../../../components/inputSearch";
import Menu from "../menu";
import { useStoreData } from "../../../../contexts/StoreData";

interface IProps {
    showInputSearch?: boolean;
    valueInputSearch?: string;
    onSearch?: () => void;
    onChangeInputSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

export default function NavBar(props: IProps) {
    const [mobileOpen, setMobileOpen] = useState(false);

    const { showInputSearch, valueInputSearch, onSearch, onChangeInputSearch, placeholder } = props;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const { store } = useStoreData();
    return (
        <>
            <AppBar color="default" position="fixed">
                <Toolbar>
                    <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                    >
                        {store?.showName ? store.showName : "SUA PAGE"}
                    </Typography>
                    {showInputSearch && (
                        <InputSearch
                            value={valueInputSearch || ""}
                            onChange={onChangeInputSearch}
                            onSearch={onSearch}
                            placeholder={placeholder}
                        />
                    )}
                </Toolbar>
            </AppBar>
            <Menu mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
        </>
    );
}
