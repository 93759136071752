import { type FormEvent, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./index.css";

import { useProductCategories } from "../../../../contexts/ProductCategories";
import NavBar from "../../components/navBar";

const theme = createTheme();

export default function CreateAndUpdateProductCategory() {
    const [categoryName, setCategoryName] = useState("");

    const { productCategories, saveProductCategory, updateProductCategory } = useProductCategories();

    const navigate = useNavigate();
    const { productCategoryId } = useParams();

    const send = async () => {
        if (productCategoryId) {
            await updateProductCategory(parseInt(productCategoryId), categoryName);
            return;
        }
        await saveProductCategory(categoryName);
        setCategoryName("");
    };

    useEffect(() => {
        if (productCategoryId) {
            const productCategory = productCategories.find((c) => c.id === parseInt(productCategoryId));
            if (productCategory) {
                setCategoryName(productCategory.name);
            }
        }
    }, [productCategoryId, productCategories]);
    return (
        <>
            <NavBar />
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Nova Categoria de Produtos
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={(e: FormEvent<HTMLFormElement>) => {
                                e.preventDefault();
                                send();
                            }}
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="productCategoryName"
                                label="Nome da Categoria"
                                name="productCategoryName"
                                autoComplete="productCategoryName"
                                autoFocus
                                value={categoryName}
                                onChange={(event) => {
                                    setCategoryName(event.target.value);
                                }}
                            />

                            <Button fullWidth variant="contained" sx={{ mt: 1, mb: 1 }} type="submit">
                                Salvar
                            </Button>

                            <Button
                                fullWidth
                                color="error"
                                variant="contained"
                                sx={{ mt: 1, mb: 1 }}
                                type="button"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Voltar
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}
