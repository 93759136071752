import { useState, useEffect } from "react";

import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import "./index.css";
import { useProductCategories } from "../../../../../contexts/ProductCategories";
import InputSearch from "../../../../components/inputSearch";
import { useProducts } from "../../../../../contexts/Products";
import NoImageImg from "../../../../../static/images/semImagem.png";
import { normalizeString } from "../../../../../commons/utils";
import { type IProduct } from "../../../../../commons/interfaces";
import ModalAddItemProduct from "../ModalAddItemProduct";

interface IProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    invoiceId: number;
}

export default function ModalAddItem(props: IProps) {
    const [marginTop, setMarginTop] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [valueSearch, setValueSearch] = useState("");
    const [productsShow, setProductsShow] = useState<IProduct[]>([]);
    const [productForAdd, setProductForAdd] = useState<IProduct | undefined>();

    const { productCategories } = useProductCategories();
    const { products } = useProducts();
    const { open, setOpen, invoiceId } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const onSearch = () => {
        if (productsShow.length) {
            if (categoryId === 0) {
                const newListProductsShow = products.filter((product) =>
                    normalizeString(product.name).includes(normalizeString(valueSearch))
                );
                setProductsShow(newListProductsShow);
            } else {
                const newListProductsShow = products.filter(
                    (product) =>
                        normalizeString(product.name).includes(normalizeString(valueSearch)) &&
                        product.categories.map((c) => c.id).includes(categoryId)
                );
                setProductsShow(newListProductsShow);
            }
        } else {
            setProductsShow(products);
        }
    };

    useEffect(() => {
        if (open) {
            setMarginTop(30);
        } else setMarginTop(0);
    }, [open]);

    useEffect(() => {
        setProductsShow(products);
    }, [products]);

    useEffect(() => {
        onSearch();
    }, [categoryId]);

    return (
        <>
            <ModalAddItemProduct product={productForAdd} setProduct={setProductForAdd} invoiceId={invoiceId} />
            <Modal open={open} onClose={handleClose} style={{ marginTop }}>
                <div className="modal-add-item-container">
                    <div className="modal-add-item-header">
                        <h1>Adicionar Item a Conta</h1>
                    </div>
                    <div className="modal-add-item-body">
                        <div className="modal-add-item-search">
                            <InputSearch
                                value={valueSearch}
                                onChange={(event) => {
                                    setValueSearch(event.target.value);
                                }}
                                onSearch={onSearch}
                            />
                        </div>

                        <div className="modal-add-item-select-category">
                            <FormControl fullWidth>
                                <InputLabel id="select-category-label">Categoria</InputLabel>
                                <Select
                                    labelId="select-category-label"
                                    id="select-category"
                                    value={categoryId}
                                    label="Categoria"
                                    onChange={(event) => {
                                        setCategoryId(event.target.value as number);
                                    }}
                                >
                                    <MenuItem value={0}>Todas</MenuItem>
                                    {productCategories.map((item) => (
                                        <MenuItem key={item.id.toString()} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="modal-add-item-products">
                            {productsShow.map((item) => (
                                <div
                                    key={`product-${item.id}`}
                                    className="modal-add-item-product"
                                    onClick={() => {
                                        setProductForAdd(item);
                                    }}
                                >
                                    <div>
                                        <img src={item.imageUrl || NoImageImg} alt={item.name} />
                                    </div>

                                    <div className="modal-add-item-product-name">{item.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-add-item-actions">
                        <Button variant="contained" color="error" fullWidth onClick={handleClose}>
                            Fechar
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
