import TokenManager from "./TokenManager";

export async function resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<File | null> {
    return await new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > maxWidth || height > maxHeight) {
                const ratio = Math.min(maxWidth / width, maxHeight / height);
                width *= ratio;
                height *= ratio;
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");
            ctx?.drawImage(img, 0, 0, width, height);

            canvas.toBlob((blob) => {
                if (blob) {
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                    });
                    resolve(resizedFile);
                } else {
                    reject(new Error("Failed to resize image."));
                }
            }, file.type);
        };

        img.onerror = () => {
            reject(new Error("Failed to load image."));
        };

        img.src = URL.createObjectURL(file);
    });
}

export const normalizeString = (value: string) => {
    return value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
};

export function removeNullAndUndefinedFields(obj: any): any {
    return JSON.parse(
        JSON.stringify(obj, (key, value) => {
            return value !== null && value !== undefined && value !== "" ? value : undefined;
        })
    );
}

export const validateAccessTokenAndRedirect = (to: string = "/products") => {
    const accessToken = TokenManager.getAccessToken();
    if (accessToken) {
        const { validated } = TokenManager.getTokenData();
        if (validated) {
            window.location.href = to;
        } else window.location.href = "/validate/email/request";
    }
};
