import { type PrintStatusType } from "../commons/interfaces";
import { foobleAPI } from "./baseApi";

export interface ICreateInvoiceItemRequest {
    invoiceId: number;
    productId: number;
    quantity: number;
    observation: string;
}

export interface IUpdateInvoiceItemRequest {
    quantity?: number;
    unityPrice?: number;
    delivered?: boolean;
    observation?: string;
    printStatus?: PrintStatusType;
}

interface ICreateInvoiceItemResponse {
    id: number;
}

export async function createInvoiceItem(data: ICreateInvoiceItemRequest) {
    return foobleAPI.post<ICreateInvoiceItemResponse>("/invoice-items", data);
}

export async function deleteInvoiceItem(invoiceItemId: number) {
    return foobleAPI.delete(`/invoice-items/${invoiceItemId}`);
}

export async function updateInvoiceItem(invoiceItemId: number, dataToUpdate: IUpdateInvoiceItemRequest) {
    return foobleAPI.put(`/invoice-items/${invoiceItemId}`, dataToUpdate);
}
