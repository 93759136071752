import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import "./index.css";
import ProductCard from "./components/ProductCard";
import { useProducts } from "../../../contexts/Products";
import NavBar from "../components/navBar";
import { type IProduct } from "../../../commons/interfaces";
import { normalizeString } from "../../../commons/utils";

export default function Products() {
    const [search, setSearch] = useState("");
    const [productsShow, setProductsShow] = useState<IProduct[]>([]);

    const { products } = useProducts();

    useEffect(() => {
        setProductsShow(products);
    }, [products]);

    const onSearch = () => {
        if (search && productsShow.length) {
            const newListProductsShow = products.filter((product) =>
                normalizeString(product.name).includes(normalizeString(search))
            );
            setProductsShow(newListProductsShow);
        } else {
            setProductsShow(products);
        }
    };

    return (
        <>
            <NavBar
                showInputSearch
                valueInputSearch={search}
                onChangeInputSearch={(event) => {
                    setSearch(event.target.value);
                }}
                onSearch={onSearch}
                placeholder="Procurar Produto"
            />
            <div className="products-container">
                <div className="products-container-actions">
                    <Link to={"/products/create"}>
                        <Button variant="contained">CRIAR NOVO PRODUTO</Button>
                    </Link>
                </div>

                <Divider sx={{ mb: "10px", mt: "10px" }} />

                <div className="list-products-container">
                    {productsShow.map((product) => (
                        <ProductCard key={product.id} product={product} />
                    ))}
                </div>
            </div>
        </>
    );
}
