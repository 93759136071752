import { authAPI } from "./baseApi";

interface IGetTokenDataRequest {
    emailOrUsername: string;
    password: string;
}

interface IGetTokenResponse {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
}

interface IRefreshTokenRequest {
    refreshToken: string;
}

export async function getToken(data: IGetTokenDataRequest) {
    return await authAPI.post<IGetTokenResponse>("/auth", data);
}

export async function refreshToken(data: IRefreshTokenRequest) {
    return await authAPI.post<IGetTokenResponse>("/auth/refresh", data);
}
