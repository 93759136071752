import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import "./index.css";

interface IProps {
    action: () => void | Promise<void>;
    modalOpen: boolean;
    setModalOpen: (value: boolean) => void;
}

export default function ModalClosingInvoice(props: IProps) {
    const [marginTop, setMarginTop] = useState(0);

    const { action, modalOpen, setModalOpen } = props;

    const handleClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (modalOpen) {
            setMarginTop(200);
        } else setMarginTop(0);
    }, [modalOpen]);

    return (
        <Modal open={modalOpen} onClose={handleClose}>
            <div className="modal-closing-invoice-container" style={{ marginTop }}>
                <div className="modal-closing-invoice-title">
                    <p>Tem Certeza que deseja fechar a sua conta?</p>
                    <p>Você não podera mais fazer Pedidos!</p>
                </div>
                <Divider style={{ marginTop: 10 }} />
                <div className="modal-closing-invoice-actions">
                    <Button onClick={action} variant="contained">
                        FECHAR CONTA
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="error">
                        CANCELAR
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
