import { useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";

import "./index.css";
import NavBar from "../components/navBar";

export default function Tables() {
    const [range, setRange] = useState("1-50");

    const handleChange = (event: SelectChangeEvent) => {
        setRange(event.target.value);
    };
    return (
        <>
            <NavBar />
            <div className="tables-container">
                <div className="tables-container-title">
                    <p>Escolha quais mesas vocề deseja gerar os QR Codes</p>
                </div>

                <Box sx={{ width: 300 }}>
                    <FormControl fullWidth>
                        <InputLabel id="label-range-tables">Intervalo de Mesas</InputLabel>
                        <Select
                            defaultValue="1-50"
                            labelId="label-range-tables"
                            id="select-range-tables"
                            value={range}
                            label="Intervalo de Mesas"
                            onChange={handleChange}
                        >
                            <MenuItem value={"1-50"}>Mesa 1 - Mesa 50</MenuItem>
                            <MenuItem value={"51-100"}>Mesa 51 - Mesa 100</MenuItem>
                            <MenuItem value={"101-150"}>Mesa 101 - Mesa 150</MenuItem>
                            <MenuItem value={"151-200"}>Mesa 151 - Mesa 200</MenuItem>
                        </Select>
                        <Link to={`/tables/generate/${range}`}>
                            <Button fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
                                GERAR QR CODES
                            </Button>
                        </Link>
                    </FormControl>
                </Box>
            </div>
        </>
    );
}
