import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import Button from "@mui/material/Button";
import { useReactToPrint } from "react-to-print";

import { useStoreData } from "../../../../contexts/StoreData";
import { BASE_URL_TABLES } from "../../../../config";
import "./index.css";
import TableParamsManager from "../../../../commons/TableParamsManager";
import NavBar from "../../components/navBar";

interface IQrCodeData {
    url: string;
    tableName: string;
}

export default function GenerateQrCodes() {
    const [qrCodesData, setQrCodesData] = useState<IQrCodeData[]>([]);

    const { range } = useParams();
    const { store } = useStoreData();
    const navigate = useNavigate();

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (range && store) {
            const values = range.split("-");
            const startValue = parseInt(values[0]);
            const endValue = parseInt(values[1]);

            const listQrCodesData: IQrCodeData[] = [];

            for (let i = startValue; i < endValue + 1; i++) {
                const tableParams = TableParamsManager.encode(store.id, i);
                const url = `${BASE_URL_TABLES}/${tableParams}/products`;
                listQrCodesData.push({
                    url,
                    tableName: `Mesa ${i}`,
                });
            }

            setQrCodesData(listQrCodesData);
        }
    }, [store]);

    useEffect(() => {
        const styleComponent = document.getElementById("print-style");
        if (styleComponent) {
            styleComponent.innerText = `@page {
                size: auto;
                margin-top: 20mm;
            }`;
        }
    }, []);

    const agruparEmGrupos = (arr: IQrCodeData[], tamanhoGrupo: number): IQrCodeData[][] => {
        return arr.reduce((grupos: IQrCodeData[][], item, index) => {
            const grupoIndex = Math.floor(index / tamanhoGrupo);

            if (!grupos[grupoIndex]) {
                grupos[grupoIndex] = [];
            }

            grupos[grupoIndex].push(item);

            return grupos;
        }, []);
    };

    return (
        <>
            {" "}
            <NavBar />
            <div className="generate-qr-codes-container">
                <div className="generate-qr-codes-actions">
                    <Button variant="contained" sx={{ mt: 2, mb: 2, fontWeight: 400 }} onClick={handlePrint}>
                        IMPRIMIR
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ mt: 2, mb: 2, fontWeight: 400 }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        VOLTAR
                    </Button>
                </div>

                <div className="generate-qr-codes-content" ref={componentRef}>
                    {agruparEmGrupos(qrCodesData, 6).map((grupo, grupoIndex) => (
                        <div className="page-break" key={`grupo-${grupoIndex}`}>
                            {grupo.map((q, index) => (
                                <div
                                    className="card-qr"
                                    key={`q-${grupoIndex}-${index}`}
                                    style={{
                                        textAlign: "center",
                                        fontWeight: 200,
                                        border: "solid 1px black",
                                        padding: "10px",
                                    }}
                                >
                                    <h3
                                        style={{
                                            fontFamily: "Roboto, sans-serif",
                                            fontWeight: 400,
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {q.tableName}
                                    </h3>
                                    <QRCode value={q.url} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
