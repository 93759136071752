import { createContext, useState, type PropsWithChildren, useContext } from "react";
import { type IInvoice } from "../../commons/interfaces";
import PageLoading from "../../pages/components/PageLoading";
import {
    findInvoiceByTableName as findInvoiceByTableNameIntegration,
    findInvoiceById as findInvoiceByIdIntegration,
    createInvoice as createInvoiceIntegration,
    closeInvoice as closeInvoiceIntegration,
    type IUpdateInvoiceRequest,
    updateInvoice as updateInvoiceIntegration,
    listInvoiceByStoreId as listInvoiceByStoreIdIntegration,
    type IIlistInvoiceParams,
} from "../../integrations/invoices";
import { useAlert } from "../Alert";

interface IInvoiceContext {
    findInvoiceByTableName: (storeId: number, tableName: number) => Promise<IInvoice | undefined>;
    findInvoiceById: (invoiceId: number) => Promise<IInvoice | undefined>;
    createInvoice: (storeId: number, tableName: number) => Promise<{ id: number } | undefined>;
    updateInvoice: (invoiceId: number, data: IUpdateInvoiceRequest) => Promise<void>;
    listInvoicesByStoreId: (storeId: number, params: IIlistInvoiceParams) => Promise<IInvoice[]>;
    invoicesFromStore: IInvoice[];
    setInvoicesFromStore: (value: IInvoice[]) => void;
    closeInvoice: (invoiceId: number) => Promise<void>;
}

export const InvoiceContext = createContext({} as IInvoiceContext);

export const useInvoices = () => useContext(InvoiceContext);

export default function Invoices({ children }: PropsWithChildren<unknown>) {
    const [isLoading, setIsLoading] = useState(false);
    const [invoicesFromStore, setInvoicesFromStore] = useState<IInvoice[]>([]);

    const { showAlert } = useAlert();

    const listInvoicesByStoreId = async (storeId: number, params: IIlistInvoiceParams) => {
        try {
            setIsLoading(true);
            const response = await listInvoiceByStoreIdIntegration(storeId, params);
            if (response) {
                setInvoicesFromStore(response.data);
                return response.data;
            }
        } catch (error) {
            showAlert("Não conseguimos listar os pedidos!", { type: "error" });
        } finally {
            setIsLoading(false);
        }
        return [];
    };

    const findInvoiceByTableName = async (storeId: number, tableName: number) => {
        try {
            setIsLoading(true);
            const response = await findInvoiceByTableNameIntegration(storeId, tableName);
            if (response) {
                return response.data;
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const findInvoiceById = async (invoiceId: number) => {
        try {
            setIsLoading(true);
            const response = await findInvoiceByIdIntegration(invoiceId);
            if (response) {
                return response.data;
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const createInvoice = async (storeId: number, tableName: number) => {
        try {
            setIsLoading(true);
            const response = await createInvoiceIntegration({
                storeId,
                tableName: tableName.toString(),
            });
            if (response) {
                return response.data;
            }
        } catch (error) {
            showAlert("Não conseguimos enviar o seu pedido", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const updateInvoice = async (invoiceId: number, data: IUpdateInvoiceRequest) => {
        try {
            setIsLoading(true);
            await updateInvoiceIntegration(invoiceId, data);
            showAlert("Dados Salvos!", { type: "success" });
        } catch (error) {
            showAlert("Tivemos um Problema para Salvar os Dados!", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const closeInvoice = async (invoiceId: number) => {
        try {
            setIsLoading(true);
            await closeInvoiceIntegration(invoiceId);
            showAlert("Conta Fechada", { type: "success" });
        } catch (error) {
            showAlert("Tivemos um Problema para fechar a esta conta!", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <InvoiceContext.Provider
            value={{
                findInvoiceByTableName,
                findInvoiceById,
                createInvoice,
                updateInvoice,
                listInvoicesByStoreId,
                invoicesFromStore,
                setInvoicesFromStore,
                closeInvoice,
            }}
        >
            <PageLoading open={isLoading} />
            {children}
        </InvoiceContext.Provider>
    );
}
