import { type FormEvent, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";

import { useProductCategories } from "../../../../contexts/ProductCategories";
import ImageInput from "../../../components/ImageInput";
import { resizeImage } from "../../../../commons/utils";
import { useProducts } from "../../../../contexts/Products";
import NavBar from "../../components/navBar";

const theme = createTheme();

export default function CrateAndUpdateProduct() {
    const [productName, setProductName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [productCategoriesSelected, setProductCategoriesSelected] = useState<number[]>([]);
    const [availableStore, setAvailableStore] = useState(true);
    const [availableDelivery, setAvailableDelivery] = useState(true);
    const [image, setImage] = useState<string | ArrayBuffer | null | undefined>(null);
    const [imageForSave, setImageForSave] = useState<File | undefined>();

    const navigate = useNavigate();
    const { productCategories } = useProductCategories();
    const { products, saveProduct, updateProduct } = useProducts();

    const { productId } = useParams();

    useEffect(() => {
        if (productId) {
            const currentProduct = products.find((p) => p.id === parseInt(productId));
            if (currentProduct) {
                setImage(currentProduct.imageUrl);
                setProductName(currentProduct.name);
                setPrice(currentProduct.price);
                setDescription(currentProduct.description);
                setProductCategoriesSelected(currentProduct.categories.map((c) => c.id));
                setAvailableDelivery(currentProduct.availableDelivery);
                setAvailableStore(currentProduct.availableStore);
            }
        }
    }, [products, productId]);

    const handleChangeImage = async (image: File) => {
        const resizedImage = await resizeImage(image, 512, 512);
        if (resizedImage) {
            setImageForSave(resizedImage);
        }
    };
    const send = async () => {
        if (productId) {
            await updateProduct(parseInt(productId), {
                name: productName,
                description,
                price,
                categoriesIds: productCategoriesSelected,
                availableStore,
                availableDelivery,
                image: imageForSave,
            });
            return;
        }
        await saveProduct({
            name: productName,
            description,
            price,
            categoriesIds: productCategoriesSelected,
            availableStore,
            availableDelivery,
            image: imageForSave,
        });
        navigate(-1);
    };
    return (
        <>
            <NavBar />
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs" sx={{ mt: -5 }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            {productId && productName ? productName : "Novo Produto"}
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={(e: FormEvent<HTMLFormElement>) => {
                                e.preventDefault();
                                send();
                            }}
                            sx={{ mt: 1 }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <ImageInput image={image} setImage={setImage} setFile={handleChangeImage} size={100} />
                            </div>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="productName"
                                label="Nome do Produto"
                                name="productName"
                                autoComplete="productName"
                                autoFocus
                                value={productName}
                                onChange={(event) => {
                                    setProductName(event.target.value);
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="productDescription"
                                label="Descrição"
                                name="productDescription"
                                autoComplete="productDescription"
                                multiline
                                rows={4}
                                autoFocus
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="productPrice"
                                label="Valor"
                                name="productPrice"
                                autoComplete="productPrice"
                                type="number"
                                autoFocus
                                value={price}
                                onChange={(event) => {
                                    setPrice(parseFloat(event.target.value));
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="select-categories-label">
                                    {`${productCategoriesSelected.length} Categorias`}
                                </InputLabel>
                                <Select
                                    labelId="select-categories-label"
                                    id="select-categories"
                                    value={productCategoriesSelected}
                                    label="categorias"
                                    multiple
                                    renderValue={() =>
                                        `${productCategoriesSelected.length} Categorias` as any as string[]
                                    }
                                    onChange={(event) => {
                                        setProductCategoriesSelected(event.target.value as number[]);
                                    }}
                                >
                                    {productCategories.map((category, index) => (
                                        <MenuItem value={category.id} key={category.id}>
                                            <Checkbox checked={productCategoriesSelected.includes(category.id)} />
                                            <ListItemText primary={category.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Divider sx={{ mt: 3 }} />
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={availableStore}
                                            onChange={(event) => {
                                                setAvailableStore(event.target.checked);
                                            }}
                                        />
                                    }
                                    label="Disponivel na Loja"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={availableDelivery}
                                            onChange={(event) => {
                                                setAvailableDelivery(event.target.checked);
                                            }}
                                        />
                                    }
                                    label="Disponivel para delivery"
                                />
                            </FormGroup>
                            <Divider sx={{ mb: 3 }} />
                            <Button fullWidth variant="contained" sx={{ mt: 1, mb: 1 }} type="submit">
                                Salvar
                            </Button>
                            <Button
                                fullWidth
                                color="error"
                                variant="contained"
                                sx={{ mt: 1, mb: 1 }}
                                type="button"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Voltar
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}
