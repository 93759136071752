import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import "./index.css";
import Table from "../../components/Table";
import { useProductCategories } from "../../../contexts/ProductCategories";
import ModalDelete from "../../components/ModalDelete";
import NavBar from "../components/navBar";

interface ICategoriesShow {
    id: number;
    nome: string;
    Ações: JSX.Element;
}

export default function ProductCategories() {
    const [categoriesShow, setCategoriesShow] = useState<ICategoriesShow[]>([]);

    const { productCategories, deleteProductCategory } = useProductCategories();

    useEffect(() => {
        const categoriesForTable: ICategoriesShow[] = productCategories.map((c) => {
            const actions = (
                <div>
                    <Link to={`/products-categories/update/${c.id}`}>
                        <IconButton>
                            <ModeEditIcon color="primary" />
                        </IconButton>
                    </Link>
                    <ModalDelete
                        title={`Tem Certeza que deseja exluir a categoria ${c.name}?`}
                        action={async () => {
                            await deleteProductCategory(c.id);
                        }}
                        buttonType="ICON"
                    />
                </div>
            );

            return {
                id: c.id,
                nome: c.name,
                Ações: actions,
            };
        });

        setCategoriesShow(categoriesForTable);
    }, [productCategories]);

    return (
        <>
            <NavBar />
            <div className="product-categories-container">
                <div className="product-categories-container-actions">
                    <Link to={"/products-categories/create"}>
                        <Button variant="contained">CRIAR NOVA CATEGORIA</Button>
                    </Link>
                </div>
                <Table rows={categoriesShow} />
            </div>
        </>
    );
}
