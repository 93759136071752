import { type IStoreAddress } from "../commons/interfaces";
import { foobleAPI } from "./baseApi";

export interface ICreateAddressRequest {
    cep: string;
    street: string;
    district: string;
    city: string;
    state: string;
    number?: number;
    complement?: string;
    country: string;
}

export interface IUpdateStoreAddressRequest {
    cep?: string;
    street?: string;
    district?: string;
    city?: string;
    state?: string;
    number?: number;
    complement?: string;
    country?: string;
}

export interface ICreateAddressResponse {
    id: number;
}

export async function createStoreAddress(storeId: number, data: ICreateAddressRequest) {
    return foobleAPI.post<ICreateAddressResponse>("/addresses", { storeId, ...data });
}

export async function findStoreAddressByStoreId(storeId: number) {
    return foobleAPI.get<IStoreAddress>(`/addresses/stores/${storeId}`);
}

export async function updateStoreAddress(addressId: number, data: IUpdateStoreAddressRequest) {
    return foobleAPI.put(`/addresses/${addressId}`, data);
}
