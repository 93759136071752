import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "./index.css";
import { type IUpdateStoreSettingsRequest, type ICreateStoreSettingsRequest } from "../../../integrations/settings";
import { useStoreData } from "../../../contexts/StoreData";
import { useStoreSettings } from "../../../contexts/storeSettings";
import NavBar from "../components/navBar";
import Footer from "../../components/Footer";

type FeeFormType = "PERCENTAGE_OF_ACCOUNT" | "FIXED_VALUE";

export default function Settings() {
    const [chargeServiceFee, setChargeServiceFee] = useState(false);
    const [feeForm, setFeeForm] = useState<FeeFormType>("PERCENTAGE_OF_ACCOUNT");
    const [feeAmount, setFeeAmount] = useState("");
    const [feeAmountErrorText, setFeeAmountErrorText] = useState<string | undefined>();
    const [feeName, setFeeName] = useState("");
    const [edited, setEdited] = useState(false);

    const { store } = useStoreData();
    const { createStoreSettings, updateStoreSettings, storeSettings } = useStoreSettings();

    const handleChangeFeeAmount = (value: string) => {
        if (/^-?\d+(?:\.\d{1,2})?\.?$/.test(value) || value === "") {
            if (feeForm === "PERCENTAGE_OF_ACCOUNT" && parseInt(value) > 100) {
                setFeeAmountErrorText(
                    "Quando a forma da taxa for porcentagem da nota, o valor não pode ser maior que 100"
                );
                return;
            }
            setFeeAmountErrorText(undefined);
            setFeeAmount(value);
        }
    };

    useEffect(() => {
        if (feeForm === "PERCENTAGE_OF_ACCOUNT" && parseInt(feeAmount) > 100) {
            setFeeAmountErrorText("Quando a forma da taxa for porcentagem da nota, o valor não pode ser maior que 100");
            setFeeAmount("100");
        } else {
            setFeeAmountErrorText(undefined);
        }
    }, [feeForm]);

    useEffect(() => {
        if (storeSettings) {
            const serviceFee = storeSettings.serviceFee;
            if (
                parseFloat(feeAmount).toFixed(2) !== (serviceFee.feeAmount || 0).toFixed(2) ||
                feeForm !== serviceFee.feeForm ||
                chargeServiceFee !== serviceFee.chargeServiceFee ||
                serviceFee.feeName !== feeName
            ) {
                setEdited(true);
            } else setEdited(false);
        } else setEdited(true);
    }, [feeAmount, feeForm, chargeServiceFee, feeName]);

    useEffect(() => {
        if (storeSettings) {
            const serviceFee = storeSettings.serviceFee;
            setChargeServiceFee(serviceFee.chargeServiceFee);
            setFeeForm(serviceFee.feeForm || "PERCENTAGE_OF_ACCOUNT");
            setFeeAmount((serviceFee.feeAmount || 0).toFixed(2));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setFeeName(serviceFee.feeName || "");
        }
    }, [storeSettings]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const save = async () => {
        if (store) {
            if (!store.settings) {
                const data: ICreateStoreSettingsRequest = {
                    storeId: store.id,
                    data: {
                        serviceFee: {
                            chargeServiceFee,
                            feeForm,
                            feeAmount: parseFloat(feeAmount),
                            feeName,
                        },
                    },
                };
                await createStoreSettings(data);
            } else {
                const data: IUpdateStoreSettingsRequest = {
                    data: {
                        serviceFee: {
                            chargeServiceFee,
                            feeForm,
                            feeAmount: parseFloat(feeAmount),
                            feeName,
                        },
                    },
                };
                await updateStoreSettings(store.id, data);
            }
        }
    };

    return (
        <>
            <NavBar />
            <div className="settings-container">
                <div className="settings-container-title">
                    <h1>Configurações</h1>
                </div>
                <div className="settings-content-card">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={chargeServiceFee}
                                onChange={(event) => {
                                    setChargeServiceFee(event.target.checked);
                                }}
                            />
                        }
                        label="Cobrar Taxa de Serviço"
                    />
                    <FormControl sx={{ width: "300px" }}>
                        <InputLabel id="fee-form-select-label">Forma da taxa</InputLabel>
                        <Select
                            labelId="fee-form-select-label"
                            id="fee-form-select"
                            value={feeForm}
                            disabled={!chargeServiceFee}
                            label="Forma da Taxa"
                            onChange={(event) => {
                                setFeeForm(event.target.value as FeeFormType);
                            }}
                        >
                            <MenuItem value={"PERCENTAGE_OF_ACCOUNT"}>PORCENTAGEM DA NOTA</MenuItem>
                            <MenuItem value={"FIXED_VALUE"}>VALOR FIXO</MenuItem>
                        </Select>
                    </FormControl>
                    <div style={{ width: "100%" }}>
                        <TextField
                            id="fee-amount"
                            fullWidth
                            type="string"
                            label="Valor da Taxa"
                            name="feeAmount"
                            value={feeAmount}
                            disabled={!chargeServiceFee}
                            onChange={(event) => {
                                handleChangeFeeAmount(event.target.value);
                            }}
                        />
                        {feeAmountErrorText && <p className="fee-amount-input-error">{feeAmountErrorText}</p>}
                    </div>
                    <TextField
                        id="feeName"
                        fullWidth
                        type="string"
                        label="Nome da Taxa"
                        name="feeName"
                        value={feeName}
                        disabled={!chargeServiceFee}
                        onChange={(event) => {
                            setFeeName(event.target.value);
                        }}
                    />
                    <div className="settings-container-card-actions">
                        <Button variant="contained" disabled={!edited} onClick={save}>
                            Salvar
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
