import { createContext, useState, type PropsWithChildren, useContext, useEffect } from "react";
import {
    type ICreateAddressRequest,
    updateStoreAddress as updateStoreAddressIntegration,
    createStoreAddress as createStoreAddressIntegration,
    findStoreAddressByStoreId,
    type IUpdateStoreAddressRequest,
    type ICreateAddressResponse,
} from "../../integrations/addresses";
import { type IStoreAddress } from "../../commons/interfaces";
import PageLoading from "../../pages/components/PageLoading";
import { useAlert } from "../Alert";
import { removeNullAndUndefinedFields } from "../../commons/utils";
import { useStoreData } from "../StoreData";

interface IStoreAddressesContext {
    createStoreAddress: (
        storeId: number,
        address: ICreateAddressRequest
    ) => Promise<ICreateAddressResponse | undefined>;
    findAddressByStoreId: (storeId: number) => Promise<IStoreAddress | undefined>;
    updateStoreAddress: (storeId: number, data: IUpdateStoreAddressRequest) => Promise<void>;
    storeAddress: IStoreAddress | undefined;
    setStoreAddress: (value: IStoreAddress) => void;
}

export const StoreAddressesContext = createContext({} as IStoreAddressesContext);

export const useStoreAddress = () => useContext(StoreAddressesContext);

export default function StoreAddresses({ children }: PropsWithChildren<unknown>) {
    const [isLoading, setIsLoading] = useState(false);
    const [storeAddress, setStoreAddress] = useState<IStoreAddress | undefined>();

    const { showAlert } = useAlert();
    const { store } = useStoreData();

    const createStoreAddress = async (storeId: number, data: ICreateAddressRequest) => {
        setIsLoading(true);
        try {
            const response = await createStoreAddressIntegration(storeId, data);
            if (response) {
                setStoreAddress({ id: response.data.id, storeId, ...data });
                showAlert("Dados Salvos", { type: "success" });
                return response.data;
            }
        } catch (error) {
            showAlert("Não conseguimos salvar os dados do Endereço!", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const findAddressByStoreId = async (storeId: number) => {
        setIsLoading(true);
        try {
            const response = await findStoreAddressByStoreId(storeId);
            if (response) {
                setStoreAddress(response.data);
                return response.data;
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const updateStoreAddress = async (storeId: number, data: IUpdateStoreAddressRequest) => {
        setIsLoading(true);
        try {
            const cleanedData = removeNullAndUndefinedFields(data);
            const response = await updateStoreAddressIntegration(storeId, cleanedData as IUpdateStoreAddressRequest);
            if (response) {
                setStoreAddress({ ...storeAddress, ...(cleanedData as IStoreAddress) });
                showAlert("Dados Salvos", { type: "success" });
            }
        } catch (error) {
            showAlert("Não conseguimos atualizar os dados do Endereço!", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (store) {
            findAddressByStoreId(store.id);
        }
    }, [store]);

    return (
        <StoreAddressesContext.Provider
            value={{ createStoreAddress, storeAddress, setStoreAddress, findAddressByStoreId, updateStoreAddress }}
        >
            <PageLoading open={isLoading} />
            {children}
        </StoreAddressesContext.Provider>
    );
}
