import { type IStoreSettings } from "../commons/interfaces";
import { foobleAPI } from "./baseApi";

interface IServiceFee {
    chargeServiceFee: boolean;
    feeForm?: "PERCENTAGE_OF_ACCOUNT" | "FIXED_VALUE";
    feeAmount?: number;
    feeName?: string;
}

export interface ICreateStoreSettingsRequest {
    storeId: number;
    data: {
        serviceFee: IServiceFee;
    };
}

export interface IUpdateStoreSettingsRequest {
    data: {
        serviceFee: IServiceFee;
    };
}

export async function createStoreSettings(data: ICreateStoreSettingsRequest) {
    return foobleAPI.post("/settings", data);
}

export async function updateStoreSettings(storeId: number, data: IUpdateStoreSettingsRequest) {
    return foobleAPI.put(`/settings/${storeId}`, data);
}

export async function readSettingsByStoreId(storeId: number) {
    return foobleAPI.get<IStoreSettings>(`/settings/${storeId}`);
}
