import { createContext, useState, type PropsWithChildren, useContext, useEffect } from "react";
import { type IProduct } from "../../commons/interfaces";
import PageLoading from "../../pages/components/PageLoading";
import { useStoreData } from "../StoreData";
import {
    createProduct,
    listProductsByStoreId,
    type IListProductsParams,
    updateProductImage,
    updateProduct as updateProductIntegration,
    deleteProduct as deleteProductIntegration,
} from "../../integrations/products";
import { useAlert } from "../Alert";
import { useProductCategories } from "../ProductCategories";

interface ICreateProductData {
    name: string;
    description: string;
    price: number;
    categoriesIds: number[];
    availableStore: boolean;
    availableDelivery: boolean;
    image?: File;
}

interface IUpdateProductData {
    name?: string;
    description?: string;
    price?: number;
    image?: File;
    availableDelivery?: boolean;
    availableStore?: boolean;
    categoriesIds?: number[];
}

interface IProductsContext {
    products: IProduct[];
    filters: IListProductsParams;
    setFilters: (value: IListProductsParams) => void;
    setProducts: (value: IProduct[]) => void;
    loadProducts: (params: IListProductsParams) => Promise<IProduct[]>;
    saveProduct: (value: ICreateProductData) => Promise<void>;
    updateProduct: (productId: number, data: IUpdateProductData) => Promise<void>;
    deleteProduct: (productId: number) => Promise<void>;
}

export const ProductContext = createContext({} as IProductsContext);

export const useProducts = () => useContext(ProductContext);

export default function Products({ children }: PropsWithChildren<unknown>) {
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [filters, setFilters] = useState<IListProductsParams>({} as IListProductsParams);

    const { showAlert } = useAlert();
    const { store } = useStoreData();
    const { productCategories } = useProductCategories();

    const loadProducts = async (params: IListProductsParams) => {
        setIsLoading(true);
        try {
            const response = await listProductsByStoreId(params);
            setProducts(response.data);
            return response.data;
        } catch (error) {
            showAlert("Não conseguimos listar os produtos");
        } finally {
            setIsLoading(false);
        }

        return [];
    };

    const saveProduct = async (product: ICreateProductData) => {
        setIsLoading(true);
        try {
            if (store) {
                const response = await createProduct({
                    ...product,
                    storeId: store.id,
                });
                if (response) {
                    let imageUrl;
                    if (product.image) {
                        try {
                            const resp = await updateProductImage(response.data.id, product.image);
                            if (resp) {
                                imageUrl = resp.data.path;
                            }
                            showAlert("Produto Criado!", {
                                type: "success",
                            });
                        } catch (error) {
                            showAlert("Tivemos um problema para salvar a imagem, tente atualiza-la em outro momento!", {
                                type: "error",
                            });
                        }
                    }
                    const newListProducts: IProduct[] = [
                        ...products,
                        {
                            ...product,
                            categories: productCategories.filter((c) => product.categoriesIds.includes(c.id)),
                            storeId: store.id,
                            id: response.data.id,
                            imageUrl,
                        },
                    ];
                    setProducts(newListProducts);
                }
            }
        } catch (error) {
            showAlert("Não conseguimos Salvar os dados", { type: "error" });
        }

        setIsLoading(false);
    };

    const updateProduct = async (productId: number, data: IUpdateProductData) => {
        setIsLoading(true);
        try {
            const response = await updateProductIntegration(productId, data);
            if (response && data.image) {
                try {
                    await updateProductImage(productId, data.image);
                    showAlert("Dados Atualizados!", {
                        type: "success",
                    });
                } catch (error) {
                    showAlert("Tivemos um problema para salvar a imagem, tente atualiza-la em outro momento!", {
                        type: "error",
                    });
                }
            } else if (response) {
                showAlert("Dados Atualizados!", {
                    type: "success",
                });
            }
        } catch (error) {
            showAlert("Tivemos um problema para atualizar os dados!", {
                type: "error",
            });
        }
        await loadProducts({ storeId: store?.id || 0 });
        setIsLoading(false);
    };

    const deleteProduct = async (productId: number) => {
        setIsLoading(true);
        try {
            await deleteProductIntegration(productId);
            const newListProducts = products.filter((product) => product.id !== productId);
            setProducts(newListProducts);
            showAlert("Produto Excluido!", {
                type: "success",
            });
        } catch (error) {
            showAlert("Não conseguimos exluir o produto, tente mais tarde!", {
                type: "error",
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (store) {
            loadProducts({ storeId: store.id });
        }
    }, [store]);

    useEffect(() => {
        if (store) {
            loadProducts(filters);
        }
    }, [filters]);

    return (
        <ProductContext.Provider
            value={{
                products,
                setProducts,
                loadProducts,
                saveProduct,
                updateProduct,
                deleteProduct,
                filters,
                setFilters,
            }}
        >
            <PageLoading open={isLoading} />
            {children}
        </ProductContext.Provider>
    );
}
