import { createContext, useState, type PropsWithChildren, useContext, useEffect } from "react";

import TokenManager from "../../commons/TokenManager";
import { type IStore } from "../../commons/interfaces";
import { useAlert } from "../Alert";
import {
    type IUpdateStoreRequest,
    listStoreByUserAuthenticated,
    readStoreById,
    updateStore as updateStoreIntegration,
    updateStoreImage as updateStoreImageIntegration,
} from "../../integrations/stores";
import PageLoading from "../../pages/components/PageLoading";
import { removeNullAndUndefinedFields } from "../../commons/utils";

interface IStoreData {
    store: IStore | undefined;
    setStore: (value: IStore) => void;
    readStore: (storeId: number) => Promise<IStore | undefined>;
    updateStore: (storeId: number, data: IUpdateStoreRequest) => Promise<void>;
    updateStoreImage: (storeId: number, image: File) => Promise<void>;
}

export const StoreDataContext = createContext({} as IStoreData);

export const useStoreData = () => useContext(StoreDataContext);

export default function StoreData({ children }: PropsWithChildren<unknown>) {
    const [store, setStore] = useState<IStore | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const readStore = async (storeId: number) => {
        try {
            if (location.pathname !== "/register-store") {
                setIsLoading(true);
                const response = await readStoreById(storeId);
                if (response) {
                    setStore(response.data);
                    return response.data;
                }
                window.location.href = "/register-store";
            }
        } catch (error) {
            showAlert("Não conseguimos obter os dados do estabelecimento");
        } finally {
            setIsLoading(false);
        }
    };

    const { showAlert } = useAlert();

    const updateStore = async (storeId: number, data: IUpdateStoreRequest) => {
        try {
            setIsLoading(true);
            const cleanedData = removeNullAndUndefinedFields(data);
            const response = await updateStoreIntegration(storeId, cleanedData as IUpdateStoreRequest);
            if (store && response) {
                setStore({ ...store, ...(cleanedData as IStore) });
                showAlert("Dados Atualizados", { type: "success" });
            }
        } catch (error) {
            showAlert("Não conseguimos atualizar seus dados");
        } finally {
            setIsLoading(false);
        }
    };

    const updateStoreImage = async (storeId: number, image: File) => {
        try {
            setIsLoading(true);
            const response = await updateStoreImageIntegration(storeId, image);
            if (store && response) {
                setStore({ ...store, imageUrl: response.data.path });
                showAlert("Imagem Atualizada", { type: "success" });
            }
        } catch (error) {
            showAlert("Tivemos um problema para salvar a imagem, tente atualiza-la em outro momento!", {
                type: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const loadStores = async () => {
            try {
                setIsLoading(true);
                const response = await listStoreByUserAuthenticated();
                if (response.data.length > 0) {
                    setStore(response.data[0]);
                } else if (location.pathname !== "/register-store") {
                    window.location.href = "/register-store";
                }
            } catch (error) {
                showAlert("Não conseguimos obter os dados do estabelecimento");
            } finally {
                setIsLoading(false);
            }
        };

        if (TokenManager.getAccessToken()) {
            const { validated } = TokenManager.getTokenData();
            if (validated) {
                loadStores();
            }
        }
    }, []);

    useEffect(() => {
        if (store) {
            document.title = store.showName;
            /* if (store.imageUrl) {
                const favicon = document.getElementById("favicon") as HTMLLinkElement;
                if (favicon) {
                    favicon.href = store.imageUrl;
                }
            } */
        }
    }, [store]);

    return (
        <StoreDataContext.Provider value={{ store, setStore, readStore, updateStore, updateStoreImage }}>
            <PageLoading open={isLoading} />
            {children}
        </StoreDataContext.Provider>
    );
}
