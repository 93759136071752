import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import { Link } from "react-router-dom";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { type IProduct } from "../../../../../commons/interfaces";
import ImgSemImagem from "../../../../../static/images/semImagem.png";
import ModalDelete from "../../../../components/ModalDelete";
import { useProducts } from "../../../../../contexts/Products";

interface IProps {
    product: IProduct;
}

export default function ProductCard(props: IProps) {
    const { product } = props;
    const { imageUrl } = product;

    const { deleteProduct } = useProducts();

    return (
        <Card sx={{ width: 320 }}>
            <div>
                <Typography level="title-lg">{product.name}</Typography>
                <Typography level="body-sm">
                    {product.description.substring(0, 80) + "..."}
                </Typography>
            </div>
            <AspectRatio minHeight="120px" maxHeight="200px">
                <img src={imageUrl || ImgSemImagem} alt={product.name} />
            </AspectRatio>
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div>
                    <Typography level="body-xs">Valor</Typography>
                    <Typography fontSize="lg" fontWeight="lg">
                        {`R$ ${product.price.toLocaleString()}`}
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                    }}
                >
                    <ModalDelete
                        buttonType="TEXT"
                        action={async () => deleteProduct(product.id)}
                        title={`Tem Certeza que deseja exluir ${product.name}?`}
                    />
                    <Link to={`/products/update/${product.id}`}>
                        <Button
                            variant="solid"
                            size="md"
                            color="primary"
                            aria-label="Explore Bahamas Islands"
                            sx={{
                                ml: "auto",
                                alignSelf: "center",
                                fontWeight: 600,
                            }}
                        >
                            EDITAR
                        </Button>
                    </Link>
                </div>
            </CardContent>
        </Card>
    );
}
