import { Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

import ProductCategories from "./ProductCategories";
import CreateAndUpdateProductCategory from "./ProductCategories/CreateAndUpdate";
import Products from "./Products";
import CreateAndUpdateProduct from "./Products/CreateAndUpdate";
import Tables from "./Tables";
import GenerateQrCodes from "./Tables/GenerateQRCodes";
import Orders from "./Orders";
import Order from "./Orders/Order";
import Profile from "./Profile";
import Settings from "./Settings";
import PageNotFound from "../components/PageNotFound";

export default function Dashboard() {
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Box
                component="main"
                sx={{
                    width: "100%",
                }}
            >
                <Toolbar />
                <Routes>
                    <Route element={<ProductCategories />} path="/products-categories" />
                    <Route element={<CreateAndUpdateProductCategory />} path="/products-categories/create" />
                    <Route
                        element={<CreateAndUpdateProductCategory />}
                        path="/products-categories/update/:productCategoryId"
                    />
                    <Route element={<Products />} path="/products" />
                    <Route element={<CreateAndUpdateProduct />} path="/products/create" />
                    <Route element={<Orders />} path="/orders" />
                    <Route element={<Order />} path="/orders/:invoiceId" />
                    <Route element={<CreateAndUpdateProduct />} path="/products/update/:productId" />
                    <Route element={<Tables />} path="/tables" />
                    <Route element={<GenerateQrCodes />} path="/tables/generate/:range" />
                    <Route element={<Profile />} path="/profile" />
                    <Route element={<Settings />} path="/settings" />
                    <Route element={<PageNotFound />} path="*" />
                </Routes>
            </Box>
        </Box>
    );
}
