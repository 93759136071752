import { foobleAPI } from "./baseApi";

import { type IProductCategory } from "../commons/interfaces";

interface ICreateProductCategoryResponse {
    id: number;
}

export async function createProductCategory(
    categoryName: string,
    storeId: number
) {
    return await foobleAPI.post<ICreateProductCategoryResponse>("/categories", {
        storeId,
        name: categoryName,
    });
}

export async function listProductCategoriesByStoreId(storeId: number) {
    return await foobleAPI.get<IProductCategory[]>(
        `/categories/stores/${storeId}`
    );
}

export async function deleteProductCategory(productCategoryId: number) {
    return await foobleAPI.delete(`/categories/${productCategoryId}`);
}

export async function updateProductCategory(
    productCategoryId: number,
    name: string
) {
    return await foobleAPI.put(`/categories/${productCategoryId}`, { name });
}
