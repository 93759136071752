class StorageManager {
    private stayConnected: boolean = false;

    constructor() {
        this.getStayConnect();
    }

    private getStayConnect() {
        const key = this.encodeValues("stayConnected");
        const value = localStorage.getItem(key);
        if (value && value === "true") {
            this.stayConnected = true;
            return true;
        }
        this.stayConnected = false;
        return false;
    }

    public setStayConnect(value: boolean) {
        const key = this.encodeValues("stayConnected");
        if (value) {
            localStorage.setItem(key, value.toString());
        } else {
            localStorage.clear();
        }
        this.stayConnected = value;
    }

    public setItem(key: string, value: string) {
        const encodedKey = this.encodeValues(key);
        const encodedValue = this.encodeValues(value);
        if (this.stayConnected) {
            localStorage.setItem(encodedKey, encodedValue);
        } else {
            sessionStorage.setItem(encodedKey, encodedValue);
        }
    }

    public getItem(key: string) {
        const encodedKey = this.encodeValues(key);
        if (this.stayConnected) {
            const encodedValue = localStorage.getItem(encodedKey);
            const decodedValue =
                encodedValue && this.decodeValues(encodedValue);
            return decodedValue || "";
        } else {
            const encodedValue = sessionStorage.getItem(encodedKey);
            const decodedValue =
                encodedValue && this.decodeValues(encodedValue);
            return decodedValue || "";
        }
    }

    public removeItem(key: string) {
        const encodedKey = this.encodeValues(key);
        localStorage.removeItem(encodedKey);
        sessionStorage.removeItem(encodedKey);
    }

    public clear() {
        localStorage.clear();
        sessionStorage.clear();
    }

    private encodeValues(value: string) {
        let valueEncoded = value;
        for (let i = 0; i < 3; i++) {
            valueEncoded = btoa(valueEncoded);
        }
        return valueEncoded;
    }

    private decodeValues(value: string) {
        let valueEncoded = value;
        for (let i = 0; i < 3; i++) {
            valueEncoded = atob(valueEncoded);
        }
        return valueEncoded;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new StorageManager();
