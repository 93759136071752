import axios, {
    type AxiosInstance,
    type InternalAxiosRequestConfig,
} from "axios";
import TokenManager from "../commons/TokenManager";

import { BASE_URL_AUTH_API, BASE_URL_FOOBLE_API } from "../config";
import { refreshToken } from "./token";

function createAPI(api: AxiosInstance) {
    api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        const newConfig = { ...config };
        newConfig.headers.Authorization = `Bearer ${TokenManager.getAccessToken()}`;
        return newConfig;
    });

    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const refreshAccessToken = TokenManager.getRefreshToken();
            if (refreshAccessToken && error.response.status === 401) {
                return await refreshToken({
                    refreshToken: refreshAccessToken,
                }).then(async () => {
                    error.config.headers.Authorization = `Bearer ${TokenManager.getAccessToken()}`;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    return await axios(error.config);
                });
            }
            return await Promise.reject(error);
        }
    );

    return api;
}

const authApi = axios.create({
    baseURL: BASE_URL_AUTH_API,
    headers: { authorization: `Bearer ${TokenManager.getAccessToken()}` },
});

const foobleApi = axios.create({
    baseURL: BASE_URL_FOOBLE_API,
    headers: { authorization: `Bearer ${TokenManager.getAccessToken()}` },
});

export const authAPI = createAPI(authApi);

export const foobleAPI = createAPI(foobleApi);
