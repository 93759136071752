import React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";

import InputSearch from "../../../components/inputSearch";
import { useStoreData } from "../../../../contexts/StoreData";

interface IProps {
    drawerWidth: number;
    handleDrawerToggle: () => void;
    inputSearchOpen?: boolean;
    onSearch?: () => void | Promise<void>;
    valueSearch?: string;
    setValueSearch?: (value: string) => void;
}

export default function NavBar(props: IProps) {
    const { drawerWidth, handleDrawerToggle, valueSearch, onSearch, inputSearchOpen, setValueSearch } = props;

    const { store } = useStoreData();

    return (
        <AppBar
            color="default"
            position="fixed"
            sx={{
                width: "100%",
                ml: { sm: `${drawerWidth}px` },
            }}
        >
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: "block" }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                >
                    {store?.showName ? store.showName : "SUA PAGE"}
                </Typography>
                {inputSearchOpen && setValueSearch && (
                    <InputSearch
                        value={valueSearch || ""}
                        onSearch={onSearch}
                        onChange={(event) => {
                            setValueSearch(event.target.value);
                        }}
                    />
                )}
            </Toolbar>
        </AppBar>
    );
}
