import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { type IStore } from "../../../../commons/interfaces";
import { useStoreData } from "../../../../contexts/StoreData";
import { resizeImage } from "../../../../commons/utils";
import ImageInput from "../../../components/ImageInput";
import "./index.css";

interface IProps {
    store: IStore | undefined;
}

export default function FormStoreData(props: IProps) {
    const [isEdit, setIsEdit] = useState(false);
    const [showName, setShowName] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [description, setDescription] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [initialImage, setInitialImage] = useState("");
    const [image, setImage] = useState<string | ArrayBuffer | null | undefined>(null);
    const [imageForSave, setImageForSave] = useState<File | undefined>();

    const { store } = props;
    const { updateStore, updateStoreImage } = useStoreData();

    const handleChangeImage = async (image: File) => {
        const resizedImage = await resizeImage(image, 256, 256);
        if (resizedImage) {
            setImageForSave(resizedImage);
        }
    };

    const save = async () => {
        if (
            store &&
            (showName !== store.showName ||
                corporateName !== store.corporateName ||
                description !== store.description ||
                cpfCnpj !== store.cpfCnpj)
        ) {
            await updateStore(store.id, {
                showName,
                corporateName,
                description,
                cpfCnpj,
            });
        }

        if (store && imageForSave) {
            await updateStoreImage(store.id, imageForSave);
        }
    };

    const reset = () => {
        if (store) {
            setShowName(store.showName);
            setCorporateName(store.corporateName);
            setDescription(store.description);
            setCpfCnpj(store.cpfCnpj);
            setImage(store.imageUrl || null);
            setInitialImage(store.imageUrl || "");
            setImageForSave(undefined);
        }
        setIsEdit(false);
    };

    useEffect(() => {
        reset();
    }, [store]);

    return (
        <>
            {!isEdit ? (
                <div className="store-data-card ">
                    <div className="store-data-card-image">
                        <img src={initialImage} alt={showName} />
                    </div>
                    <div className="store-data-card-item">
                        <span>
                            <b>Nome de exibição:</b>
                        </span>
                        <span>{store?.showName}</span>
                    </div>
                    <div className="store-data-card-item">
                        <span>
                            <b>Razão Social:</b>
                        </span>
                        <span>{store?.corporateName}</span>
                    </div>
                    <div className="store-data-card-item">
                        <span>
                            <b>Descrição:</b>
                        </span>
                        <span>{store?.description}</span>
                    </div>
                    <div className="store-data-card-item">
                        <span>
                            <b>CPF ou CNPJ:</b>
                        </span>
                        <span>{store?.cpfCnpj}</span>
                    </div>
                    <div className="store-data-card-actions">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        >
                            EDITAR
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="store-data-edit-container">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <ImageInput
                                            image={image}
                                            setImage={setImage}
                                            setFile={handleChangeImage}
                                            size={100}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="showName"
                                        label="nome do estabelecimento"
                                        name="showName"
                                        autoComplete="showName"
                                        value={showName}
                                        onChange={(event) => {
                                            setShowName(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="corporateName"
                                        label="Razão Social"
                                        name="corporateName"
                                        autoComplete="corporateName"
                                        value={corporateName}
                                        onChange={(event) => {
                                            setCorporateName(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="description"
                                        label={"description"}
                                        id="description"
                                        autoComplete="description"
                                        multiline
                                        rows={3}
                                        value={description}
                                        onChange={(event) => {
                                            setDescription(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="cpfCnpj"
                                        label="CPF ou CNPJ"
                                        name="cpfCnpj"
                                        autoComplete="cpfCnpj"
                                        value={cpfCnpj}
                                        onChange={(event) => {
                                            setCpfCnpj(event.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={save}>
                                {"SALVAR"}
                            </Button>
                            <Button fullWidth variant="contained" color="error" sx={{ mb: 2 }} onClick={reset}>
                                {"CANCELAR"}
                            </Button>
                        </Box>
                    </Box>
                </div>
            )}
        </>
    );
}
