import { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import PageLoading from "../components/PageLoading";
import { createStore, updateStoreImage } from "../../integrations/stores";
import { useAlert } from "../../contexts/Alert";
import ImageInput from "../components/ImageInput";
import { resizeImage } from "../../commons/utils";
import { useStoreData } from "../../contexts/StoreData";

export default function RegisterStore() {
    const [showName, setShowName] = useState("");
    const [showNameError, setShowNameError] = useState<string | undefined>();
    const [corporateName, setCorporateName] = useState("");
    const [corporateNameError, setCorporateNameError] = useState<string | undefined>();
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState<string | undefined>();
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [cpfCnpjError, setCpfCnpjError] = useState<string | undefined>();
    const [image, setImage] = useState<string | ArrayBuffer | null>(null);
    const [imageForSave, setImageForSave] = useState<File | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const theme = createTheme();
    const { showAlert } = useAlert();

    const { store } = useStoreData();

    const handleChangeImage = async (image: File) => {
        const resizedImage = await resizeImage(image, 256, 256);
        if (resizedImage) {
            setImageForSave(resizedImage);
        }
    };

    useEffect(() => {
        if (store) {
            window.location.href = "/products";
        }
    }, [store]);

    const validateFields = () => {
        let isValid = true;
        if (!showName) {
            isValid = false;
            setShowNameError("Por favor, preencha o nome do estabelecimento");
        } else {
            setCorporateNameError("");
        }

        if (!corporateName) {
            isValid = false;
            setCorporateNameError("Por favor preencha o campo Razão Social");
        } else {
            setCorporateNameError("");
        }

        if (!description) {
            isValid = false;
            setDescriptionError("Por favor, preencha o campo descrição");
        } else {
            setDescriptionError("");
        }

        if (!cpfCnpj) {
            isValid = false;
            setCpfCnpjError("Por favor, preencha o campo CPF ou CNPJ");
        } else {
            setCpfCnpjError("");
        }

        return isValid;
    };
    const save = async () => {
        if (validateFields()) {
            setIsLoading(true);
            try {
                const response = await createStore({
                    showName,
                    corporateName,
                    description,
                    cpfCnpj,
                });

                if (response && imageForSave) {
                    const storeId = response.data.id;
                    try {
                        await updateStoreImage(storeId, imageForSave);
                    } catch (error) {
                        showAlert("Tivemos um problema para salvar a imagem, tente atualiza-la em outro momento!", {
                            type: "error",
                        });
                        window.location.reload();
                    }
                }
            } catch (error) {
                showAlert("Não conseguimos salvar os dados do estabelecimento", { type: "error" });
            }
            window.location.reload();
        }
        setIsLoading(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <PageLoading open={isLoading} />
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Cadastrar Estabelecimento
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <ImageInput
                                        image={image}
                                        setImage={setImage}
                                        setFile={handleChangeImage}
                                        size={100}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={!!showNameError}
                                    fullWidth
                                    id="showName"
                                    label="nome do estabelecimento"
                                    name="showName"
                                    autoComplete="showName"
                                    value={showName}
                                    onChange={(event) => {
                                        setShowName(event.target.value);
                                    }}
                                />
                                <p style={{ margin: 0, color: "red" }}>{showNameError}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={!!corporateNameError}
                                    fullWidth
                                    id="corporateName"
                                    label="Razão Social"
                                    name="corporateName"
                                    autoComplete="corporateName"
                                    value={corporateName}
                                    onChange={(event) => {
                                        setCorporateName(event.target.value);
                                    }}
                                />
                                <p style={{ margin: 0, color: "red" }}>{corporateNameError}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={!!descriptionError}
                                    fullWidth
                                    name="description"
                                    label={"description"}
                                    id="description"
                                    autoComplete="description"
                                    value={description}
                                    onChange={(event) => {
                                        setDescription(event.target.value);
                                    }}
                                />
                                <p style={{ margin: 0, color: "red" }}>{descriptionError}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={!!cpfCnpjError}
                                    fullWidth
                                    id="cpfCnpj"
                                    label="CPF ou CNPJ"
                                    name="cpfCnpj"
                                    autoComplete="cpfCnpj"
                                    value={cpfCnpj}
                                    onChange={(event) => {
                                        setCpfCnpj(event.target.value);
                                    }}
                                />
                                <p style={{ margin: 0, color: "red" }}>{cpfCnpjError}</p>
                            </Grid>
                        </Grid>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={async () => {
                                await save();
                            }}
                        >
                            {"SALVAR"}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
