import { useState, useEffect, type FormEvent } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import { type IStore } from "../../../../commons/interfaces";
import "./index.css";
import { useTelephones } from "../../../../contexts/Telephones";

interface IProps {
    store: IStore | undefined;
}

export default function FormTelephones(props: IProps) {
    const [isEdit, setIsEdit] = useState(false);
    const [ddd, setDdd] = useState("");
    const [number, setNumber] = useState("");

    const { store } = props;
    const { telephones, createTelephone, deleteTelephone } = useTelephones();

    const handleDelete = async (telephoneId: number) => {
        await deleteTelephone(telephoneId);
    };

    const handleChangeDDD = (value: string) => {
        if (/^\d+$/.test(value) || value === "") {
            if (value.length <= 2) {
                setDdd(value);
            }
        }
    };

    const handleChangeNumber = (value: string) => {
        if (/^\d+$/.test(value) || value === "") {
            if (value.length <= 10) {
                setNumber(value);
            }
        }
    };

    const save = async () => {
        if (store) {
            const response = await createTelephone(store.id, { ddd, number });
            if (response) {
                setIsEdit(false);
                setDdd("");
                setNumber("");
            }
        }
    };

    useEffect(() => {
        if (isEdit) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [isEdit]);

    return (
        <>
            <div className="telephones-data-card">
                {telephones.length > 0 ? (
                    <div className="telephones-data-card-title">
                        <h2>Telefones</h2>
                    </div>
                ) : (
                    <div className="telephones-data-card-title">
                        <h2>Nenhum Telefone Cadastrado</h2>
                    </div>
                )}
                {telephones.map((tel) => (
                    <div key={`tel-${tel.id}`} className="telephones-data-card-item">
                        <div className="telephones-data-card-item-data">
                            <span>
                                <b>{tel.ddd}</b>
                            </span>
                            <span>{tel.number}</span>
                        </div>

                        <div>
                            <IconButton
                                onClick={() => {
                                    handleDelete(tel.id);
                                }}
                            >
                                <DeleteForeverIcon color="primary" fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                ))}
                <div className="telephones-data-card-actions">
                    <IconButton
                        onClick={() => {
                            setIsEdit(true);
                        }}
                    >
                        <AddCircleIcon color="primary" fontSize="large" />
                    </IconButton>
                </div>
            </div>

            {isEdit && (
                <Box
                    component="form"
                    onSubmit={(e: FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        save();
                    }}
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div className="telephones-data-edit-container">
                        <div className="telephones-data-edit-item">
                            <div className="ddd-item">
                                <TextField
                                    fullWidth
                                    required
                                    id="ddd"
                                    label="DDD"
                                    name="ddd"
                                    value={ddd}
                                    onChange={(event) => {
                                        handleChangeDDD(event.target.value);
                                    }}
                                />
                            </div>

                            <TextField
                                fullWidth
                                required
                                id="telephone"
                                label="Telefone"
                                name="telephone"
                                value={number}
                                onChange={(event) => {
                                    handleChangeNumber(event.target.value);
                                }}
                            />
                        </div>
                        <div className="telephones-data-edit-actions">
                            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} type="submit">
                                {"SALVAR"}
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                sx={{ mb: 2 }}
                                onClick={() => {
                                    setIsEdit(false);
                                    setDdd("");
                                    setNumber("");
                                }}
                            >
                                {"CANCELAR"}
                            </Button>
                        </div>
                    </div>
                </Box>
            )}
        </>
    );
}
