import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";

import "./index.css";

type ButtonType = "ICON" | "TEXT";

interface IProps {
    title: string;
    action: () => void | Promise<void>;
    buttonType: ButtonType;
}

export default function ModalDelete(props: IProps) {
    const [open, setOpen] = useState(false);
    const [marginTop, setMarginTop] = useState(0);

    const { title, action, buttonType } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleAction = async () => {
        await action();
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            setMarginTop(200);
        } else setMarginTop(0);
    }, [open]);

    return (
        <>
            {buttonType === "ICON" ? (
                <IconButton
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <DeleteForeverIcon color="primary" />
                </IconButton>
            ) : (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        setOpen(true);
                    }}
                    sx={{
                        ml: "auto",
                        alignSelf: "center",
                        fontWeight: 600,
                    }}
                >
                    EXCLUIR
                </Button>
            )}
            <Modal open={open} onClose={handleClose}>
                <div className="modal-delete-container" style={{ marginTop }}>
                    <div className="modal-delete-title">
                        <p>{title}</p>
                    </div>
                    <Divider style={{ marginTop: 10 }} />
                    <div className="modal-delete-actions">
                        <Button onClick={handleAction} variant="contained">
                            SIM
                        </Button>
                        <Button onClick={handleClose} variant="contained" color="error">
                            FECHAR
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
