import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { useStoreData } from "../../../../contexts/StoreData";
import { useProducts } from "../../../../contexts/Products";
import { type IProduct } from "../../../../commons/interfaces";
import ImgSemImage from "../../../../static/images/semImagem.png";
import "./index.css";
import ModalConfirmationOrder from "../../../components/ModalConfirmationOrder";
import TableParamsManager from "../../../../commons/TableParamsManager";
import StorageManager from "../../../../commons/StorageManager";
import { useInvoices } from "../../../../contexts/Invoices";
import { useInvoiceItems } from "../../../../contexts/InvoiceItems";
import ModalAlert from "../../../components/ModalAlert";
import { useAlert } from "../../../../contexts/Alert";

interface IInvoiceInStorage {
    invoiceId: number;
    tableName: string;
    createdAt: Date;
    closingDateTime?: string;
}

interface IProps {
    setInputSearchOpen: (value: boolean) => void;
    searchValue?: string;
}

export default function Product(props: IProps) {
    const [product, setProduct] = useState<IProduct | undefined>();
    const [observation, setObservation] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAcceptUseInvoiceOpen, setModalAcceptUseInvoiceOpen] = useState(false);
    const [timeWait, setTimeWait] = useState(0);
    const [agreedToUseOpenInvoice, setAgreedToUseOpenInvoice] = useState(false);

    const { setInputSearchOpen } = props;

    const { productId, tableParams } = useParams();
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const { store, readStore } = useStoreData();
    const { products } = useProducts();
    const { findInvoiceById, findInvoiceByTableName, createInvoice } = useInvoices();
    const { createInvoiceItem } = useInvoiceItems();

    useEffect(() => {
        setInputSearchOpen(false);
    }, []);

    useEffect(() => {
        const load = async () => {
            if (tableParams && !store) {
                const { storeId } = TableParamsManager.decode(tableParams);
                await readStore(storeId);
            }
        };

        load();
    }, []);

    useEffect(() => {
        if (productId && products.length) {
            const currentProduct = products.find((p) => p.id === parseInt(productId));
            if (currentProduct) {
                setProduct(currentProduct);
            }
        }
    }, [products, productId]);

    useEffect(() => {
        if (agreedToUseOpenInvoice) {
            send();
        }
    }, [agreedToUseOpenInvoice]);

    const addInvoiceIntoStorage = (invoiceData: IInvoiceInStorage) => {
        StorageManager.setItem("invoiceData", JSON.stringify(invoiceData));
    };

    const send = async () => {
        let shouldCreateNewInvoice = true;
        let invoiceId: number | undefined;
        const invoiceInStorage = StorageManager.getItem("invoiceData");
        if (invoiceInStorage) {
            const invoiceInStorageData: IInvoiceInStorage = JSON.parse(invoiceInStorage);
            const currentTime = new Date();
            const createdAt = new Date(invoiceInStorageData.createdAt);
            if (invoiceInStorageData.closingDateTime) {
                const closingDateTime = new Date(invoiceInStorageData.closingDateTime);
                const diff = (currentTime.getTime() - closingDateTime.getTime()) / 1000 / 60;
                if (diff < 60) {
                    setTimeWait(parseInt((60 - diff).toString()));
                    setModalAlertOpen(true);
                    setModalOpen(false);
                    return;
                }
            } else {
                const diff = (currentTime.getTime() - createdAt.getTime()) / 1000 / 60;
                if (diff > 120) {
                    const invoice = await findInvoiceById(invoiceInStorageData.invoiceId);
                    if (!invoice?.closingDatetime) {
                        shouldCreateNewInvoice = false;
                    }
                } else {
                    shouldCreateNewInvoice = false;
                }
            }

            if (!shouldCreateNewInvoice) invoiceId = invoiceInStorageData.invoiceId;
        }

        if (shouldCreateNewInvoice) {
            StorageManager.clear();
            const { tableName, storeId } = TableParamsManager.decode(tableParams!);
            const invoiceOpened = await findInvoiceByTableName(storeId, tableName);
            if (invoiceOpened && !agreedToUseOpenInvoice) {
                setModalAcceptUseInvoiceOpen(true);
                return;
            } else if (invoiceOpened && agreedToUseOpenInvoice) {
                invoiceId = invoiceOpened.id;
            } else {
                const response = await createInvoice(storeId, tableName);
                if (response) {
                    invoiceId = response.id;
                }
            }
            addInvoiceIntoStorage({
                invoiceId: invoiceId!,
                createdAt: new Date(),
                tableName: tableName.toString(),
            });
        }

        if (invoiceId) {
            const response = await createInvoiceItem({
                invoiceId,
                productId: parseInt(productId!),
                quantity,
                observation,
            });
            if (response) {
                showAlert("Pedido Efetuado", { type: "success" });
                setModalOpen(false);
                navigate(-1);
            }
        }
    };

    return (
        <div className="table-store-product-container">
            <ModalConfirmationOrder
                productName={product?.name || ""}
                action={send}
                observation={observation}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                quantity={quantity}
                productPrice={product?.price || 0}
            />
            <ModalAlert
                modalOpen={modalAlertOpen}
                text={`Voce Acabou de Fechar uma conta, espere ${timeWait} minutos para começar a fazer pedidos`}
                setModalOpen={setModalAlertOpen}
            />
            <ModalAlert
                modalOpen={modalAcceptUseInvoiceOpen}
                text={
                    "Esta Mesa Ja Possui uma conta aberta,caso voce esteja com a pessoa que abriu esta conta clique em OK." +
                    "Caso você não esteja com a pessoa que abriu esta conta fale com o garçon!"
                }
                setModalOpen={setModalAcceptUseInvoiceOpen}
                action={() => {
                    setAgreedToUseOpenInvoice(true);
                }}
            />
            <div className="product-image">
                <img src={product?.imageUrl || ImgSemImage} alt={product?.name} />
            </div>

            <div className="product-title">
                <h1 style={{ fontFamily: "Roboto", fontWeight: 400 }}>{product?.name}</h1>
            </div>

            <Card variant="outlined" className="product-description-card">
                <CardContent>
                    <Typography variant="h5" component="div" sx={{ fontSize: 20, mb: 1 }}>
                        Descrição
                    </Typography>
                    <Typography variant="body2" sx={{ fontFamily: "Roboto", fontWeight: 300 }}>
                        {product?.description}
                    </Typography>
                </CardContent>
            </Card>

            <div className="product-price-card">
                <h5
                    style={{
                        fontSize: 22,
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        color: "red",
                    }}
                >
                    Valor
                </h5>
                <p
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: 20,
                    }}
                >
                    {`R$ ${product?.price.toLocaleString()}`}
                </p>
            </div>
            <div className="table-store-product-inputs">
                <TextField
                    margin="normal"
                    fullWidth
                    id="orderObservation"
                    label="Observação"
                    name="orderObservation"
                    autoComplete="orderObservation"
                    placeholder="ex: sem azeitona e sem molho"
                    multiline
                    rows={3}
                    value={observation}
                    onChange={(event) => {
                        setObservation(event.target.value);
                    }}
                />
                <div className="table-store-product-input-qt">
                    <p>Quantidade:</p>
                    <div>
                        <IconButton
                            onClick={() => {
                                setQuantity(quantity - 1);
                            }}
                            disabled={quantity <= 1}
                            color="primary"
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                        <span>{quantity}</span>
                        <IconButton
                            onClick={() => {
                                setQuantity(quantity + 1);
                            }}
                        >
                            <AddCircleIcon color="primary" />
                        </IconButton>
                    </div>
                </div>
            </div>

            <div className="table-store-product-actions">
                <Button
                    onClick={() => {
                        setModalOpen(true);
                    }}
                    variant="contained"
                    fullWidth
                >
                    PEDIR
                </Button>
                <Button
                    onClick={() => {
                        navigate(-1);
                    }}
                    variant="contained"
                    color="error"
                    fullWidth
                >
                    VOLTAR
                </Button>
            </div>
        </div>
    );
}
