import { useState, useEffect } from "react";
import { useParams, Route, Routes, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

import NavBar from "./components/navBar";
import Menu from "./components/menu";

import TableStoreProducts from "./TableStoreProducts";
import Product from "./TableStoreProducts/Product";
import TableStoreOrders from "./TableStoreOrders";
import PageNotFound from "../components/PageNotFound";
import StorageManager from "../../commons/StorageManager";

export default function TableStore() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [inputSearchOpen, setInputSearchOpen] = useState(false);
    const [valueSearch, setValueSearch] = useState("");
    const [searchParams, setSearchParams] = useState<string | undefined>();

    const { pathname } = useLocation();
    const { tableParams } = useParams();

    useEffect(() => {
        if (mobileOpen) {
            setValueSearch("");
            setSearchParams(undefined);
        }
    }, [pathname]);

    useEffect(() => {
        StorageManager.setStayConnect(true);
    }, []);

    const drawerWidth = 240;
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div>
            <CssBaseline />
            <NavBar
                drawerWidth={drawerWidth}
                handleDrawerToggle={handleDrawerToggle}
                inputSearchOpen={inputSearchOpen}
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                onSearch={() => {
                    setSearchParams(valueSearch);
                }}
            />
            <Menu
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                drawerWidth={drawerWidth}
                tableParams={tableParams || ""}
            />
            <div style={{ width: "100%" }}>
                <Toolbar />
                <Routes>
                    <Route
                        element={
                            <TableStoreProducts setInputSearchOpen={setInputSearchOpen} searchValue={searchParams} />
                        }
                        path="/products"
                    />
                    <Route element={<Product setInputSearchOpen={setInputSearchOpen} />} path="/products/:productId" />
                    <Route element={<TableStoreOrders setInputSearchOpen={setInputSearchOpen} />} path="/invoice" />
                    <Route
                        element={
                            <TableStoreProducts setInputSearchOpen={setInputSearchOpen} searchValue={searchParams} />
                        }
                        path="/products/category/:productCategoryId"
                    />
                    <Route element={<PageNotFound />} path="*" />
                </Routes>
            </div>
        </div>
    );
}
