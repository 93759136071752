import React from "react";
import Avatar from "@mui/material/Avatar";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ImgSemImagem from "../../../static/images/semImagem.png";

interface IProps {
    image: string | ArrayBuffer | null | undefined;
    setImage: (image: string | ArrayBuffer | null | undefined) => void;
    setFile: (file: File) => void;
    size?: number;
}

export default function ImageInput(props: IProps) {
    const { image, setImage, setFile } = props;

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            {!image ? (
                <>
                    <label htmlFor="icon-button-file">
                        <Avatar
                            sx={{
                                cursor: "pointer",
                                width: "50px",
                                height: "50px",
                            }}
                        >
                            <PhotoCamera />
                        </Avatar>
                    </label>
                    <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        onChange={handleImageChange}
                    />
                </>
            ) : (
                <>
                    <img
                        src={(image as string) || ImgSemImagem}
                        alt=""
                        style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            margin: "auto",
                        }}
                    />
                    <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="icon-button-file">
                        <Avatar
                            sx={{
                                cursor: "pointer",
                                width: "35px",
                                height: "35px",
                                left: "165px",
                                bottom: "30px",
                            }}
                        >
                            <PhotoCamera />
                        </Avatar>
                    </label>
                </>
            )}
        </div>
    );
}
