import "./index.css";

export default function PageNotFound() {
    return (
        <div className="not-found-container">
            <p className="not-found-error">404</p>
            <p className="not-found-message">Pagina Não Encontrada</p>
        </div>
    );
}
