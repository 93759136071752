import { type IProduct } from "../commons/interfaces";
import { removeNullAndUndefinedFields } from "../commons/utils";
import { foobleAPI } from "./baseApi";

interface ICreateProductRequest {
    storeId: number;
    name: string;
    description: string;
    price: number;
    categoriesIds: number[];
    imageUrl?: string;
    availableStore: boolean;
    availableDelivery: boolean;
}

interface IUpdateProductRequest {
    name?: string;
    description?: string;
    price?: number;
    imageUrl?: string;
    availableDelivery?: boolean;
    availableStore?: boolean;
    categoriesIds?: number[];
}

interface ICreateProductResponse {
    id: number;
}

interface IUpdateImageResponse {
    path: string;
}

export interface IListProductsParams {
    storeId: number;
    availableStore?: boolean;
    availableDelivery?: boolean;
}

export async function createProduct(product: ICreateProductRequest) {
    return await foobleAPI.post<ICreateProductResponse>("/products", product);
}

export async function updateProductImage(productId: number, imageFile: File) {
    const formData = new FormData();
    formData.append("image", imageFile);
    return await foobleAPI.put<IUpdateImageResponse>(`/products/${productId}/image`, formData);
}

export async function listProductsByStoreId(params: IListProductsParams) {
    return await foobleAPI.get<IProduct[]>("/products", {
        params: removeNullAndUndefinedFields(params),
    });
}

export async function updateProduct(productId: number, data: IUpdateProductRequest) {
    return await foobleAPI.put(`/products/${productId}`, data);
}

export async function deleteProduct(productId: number) {
    return foobleAPI.delete(`/products/${productId}`);
}
