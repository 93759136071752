import { createContext, useState, type PropsWithChildren, useContext, useEffect } from "react";

import { type IProductCategory } from "../../commons/interfaces";
import {
    createProductCategory,
    listProductCategoriesByStoreId,
    deleteProductCategory as deleteCategory,
    updateProductCategory as updateCategory,
} from "../../integrations/productCategories";
import { useStoreData } from "../StoreData";
import { useAlert } from "../Alert";
import PageLoading from "../../pages/components/PageLoading";

interface IProductCategoriesContext {
    productCategories: IProductCategory[];
    setProductCategories: (value: IProductCategory[]) => void;
    loadProductCategories: () => Promise<IProductCategory[]>;
    saveProductCategory: (categoryName: string) => Promise<void>;
    deleteProductCategory: (productCategoryId: number) => Promise<void>;
    updateProductCategory: (productCategoryId: number, name: string) => Promise<void>;
}

export const ProductCategoriesContext = createContext({} as IProductCategoriesContext);

export const useProductCategories = () => useContext(ProductCategoriesContext);

export default function ProductCategories({ children }: PropsWithChildren<unknown>) {
    const [productCategories, setProductCategories] = useState<IProductCategory[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const { store } = useStoreData();
    const { showAlert } = useAlert();

    const loadProductCategories = async () => {
        setIsLoading(true);
        if (store) {
            try {
                const response = await listProductCategoriesByStoreId(store.id);
                setProductCategories(response.data);
                return response.data;
            } catch (error) {
                showAlert("Não conseguimos listar as categorias de produtos");
            } finally {
                setIsLoading(false);
            }
        }
        setIsLoading(false);
        return [];
    };

    const saveProductCategory = async (categoryName: string) => {
        setIsLoading(true);
        try {
            if (store) {
                const response = await createProductCategory(categoryName, store.id);
                if (response) {
                    const newProductCategory: IProductCategory = {
                        storeId: store.id,
                        id: response.data.id,
                        name: categoryName,
                    };

                    setProductCategories([...productCategories, newProductCategory]);
                    showAlert("Categoria Salva", { type: "success" });
                }
            }
        } catch (error) {
            showAlert("Não conseguimos Salvar os dados", { type: "error" });
        }
        setIsLoading(false);
    };

    const deleteProductCategory = async (productCategoryId: number) => {
        setIsLoading(true);
        try {
            await deleteCategory(productCategoryId);
            const newProductsCategories = productCategories.filter((c) => c.id !== productCategoryId);
            setProductCategories(newProductsCategories);
            showAlert("Categoria exlcuida", { type: "success" });
        } catch (error) {
            showAlert("Não conseguimos excluir os dados", { type: "error" });
        }
        setIsLoading(false);
    };

    const updateProductCategory = async (productCategoryId: number, name: string) => {
        setIsLoading(true);
        try {
            await updateCategory(productCategoryId, name);
            const newProductsCategories: IProductCategory[] = productCategories.map((c) => {
                if (c.id === productCategoryId) {
                    return {
                        storeId: store?.id || 0,
                        id: productCategoryId,
                        name,
                    };
                }
                return c;
            });
            setProductCategories(newProductsCategories);
            showAlert("Dados Atualizados", { type: "success" });
        } catch (error) {
            showAlert("Não conseguimos atualizar os dados", { type: "error" });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadProductCategories();
    }, [store]);

    return (
        <ProductCategoriesContext.Provider
            value={{
                productCategories,
                setProductCategories,
                loadProductCategories,
                saveProductCategory,
                deleteProductCategory,
                updateProductCategory,
            }}
        >
            <PageLoading open={isLoading} />
            {children}
        </ProductCategoriesContext.Provider>
    );
}
