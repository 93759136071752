import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useProducts } from "../../../contexts/Products";
import { useStoreData } from "../../../contexts/StoreData";
import ProductCard from "../components/ProductCard";

import "./index.css";
import TableParamsManager from "../../../commons/TableParamsManager";
import { type IProduct } from "../../../commons/interfaces";
import { normalizeString } from "../../../commons/utils";

interface IProps {
    setInputSearchOpen: (value: boolean) => void;
    searchValue?: string;
}

export default function TableStoreProducts(props: IProps) {
    const [productsShow, setProductsShow] = useState<IProduct[]>([]);

    const { setInputSearchOpen, searchValue } = props;

    const { products, setFilters } = useProducts();
    const { tableParams, productCategoryId } = useParams();
    const { readStore, store } = useStoreData();

    useEffect(() => {
        const load = async () => {
            if (tableParams && !store) {
                const { storeId } = TableParamsManager.decode(tableParams);
                await readStore(storeId);
                setFilters({ storeId, availableStore: true });
            }
        };

        load();

        setInputSearchOpen(true);
    }, []);

    useEffect(() => {
        if (searchValue && products.length && !productCategoryId) {
            const newListProductsShow = products.filter((product) =>
                normalizeString(product.name).includes(normalizeString(searchValue))
            );
            setProductsShow(newListProductsShow);
        } else if (searchValue && products.length && productCategoryId) {
            const newList = products.filter((p) => p.categories.map((c) => c.id).includes(parseInt(productCategoryId)));
            const newListProductsShow = newList.filter((product) =>
                normalizeString(product.name).includes(normalizeString(searchValue))
            );
            setProductsShow(newListProductsShow);
        } else if (!searchValue && products.length && productCategoryId) {
            const newList = products.filter((p) => p.categories.map((c) => c.id).includes(parseInt(productCategoryId)));
            setProductsShow(newList);
        } else setProductsShow(products);
    }, [searchValue, products, productCategoryId]);

    useEffect(() => {
        if (products.length && productCategoryId) {
            const newList = products.filter((p) => p.categories.map((c) => c.id).includes(parseInt(productCategoryId)));
            setProductsShow(newList);
        } else if (products.length && !productCategoryId) setProductsShow(products);
    }, [products, productCategoryId]);

    return (
        <div className="table-store-products-container">
            <div className="table-store-products-content">
                {productsShow.map((p) => (
                    <ProductCard key={`p-${p.id}`} product={p} tableParams={tableParams || ""} />
                ))}
            </div>
        </div>
    );
}
