import { useState, useEffect } from "react";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import "./index.css";
import { type IProduct } from "../../../../../commons/interfaces";
import NoImageIMG from "../../../../../static/images/semImagem.png";
import { useInvoiceItems } from "../../../../../contexts/InvoiceItems";
import { useAlert } from "../../../../../contexts/Alert";
import ModalConfirmationOrder from "../../../../components/ModalConfirmationOrder";
import { useInvoices } from "../../../../../contexts/Invoices";

interface IProps {
    product?: IProduct;
    setProduct: (value: IProduct | undefined) => void;
    invoiceId: number;
}

export default function ModalAddItemProduct(props: IProps) {
    const [open, setOpen] = useState(false);
    const [observation, setObservation] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [modalConfirmationOrderOpen, setModalConfirmationOrderOpen] = useState(false);
    const [containerRef, setContainerRef] = useState<Element | null>(null);

    const { product, setProduct, invoiceId } = props;

    const { createInvoiceItem } = useInvoiceItems();
    const { findInvoiceById, setInvoicesFromStore, invoicesFromStore } = useInvoices();
    const { showAlert } = useAlert();

    const handleClose = () => {
        setProduct(undefined);
    };

    useEffect(() => {
        setObservation("");
        setQuantity(1);
        setOpen(!!product);
    }, [product]);

    const scrollToTheEnd = async () => {
        if (open && containerRef?.scrollHeight) {
            containerRef.scrollTo({
                top: containerRef.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        scrollToTheEnd();
    }, [open, containerRef]);

    const sendOrder = async () => {
        if (product) {
            const response = await createInvoiceItem({
                invoiceId,
                productId: product.id,
                quantity,
                observation,
            });
            if (response) {
                showAlert("Pedido Efetuado", { type: "success" });
                setModalConfirmationOrderOpen(false);
                handleClose();
                const invoiceResponse = await findInvoiceById(invoiceId);
                if (invoiceResponse) {
                    const newListInvoice = invoicesFromStore.map((i) => {
                        if (i.id === invoiceId) {
                            return invoiceResponse;
                        }
                        return i;
                    });
                    setInvoicesFromStore(newListInvoice);
                }
            }
        }
    };

    return (
        <>
            <ModalConfirmationOrder
                productName={product?.name || ""}
                quantity={quantity}
                productPrice={product?.price || 0}
                action={sendOrder}
                observation={observation}
                modalOpen={modalConfirmationOrderOpen}
                setModalOpen={setModalConfirmationOrderOpen}
            />
            <Modal open={open} onClose={handleClose}>
                <div className="modal-add-item-product-container" id="container-ref" ref={setContainerRef}>
                    <div className="modal-add-item-product-header">
                        <h1>{product?.name || ""}</h1>
                    </div>
                    <div className="modal-add-item-product-body">
                        <div className="modal-add-item-product-image">
                            <img src={product?.imageUrl || NoImageIMG} alt={product?.name || "Sua Page"} />
                        </div>
                        <Card variant="outlined" className="modal-add-item-product-description-card">
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ fontSize: 20, mb: 1 }}>
                                    Descrição
                                </Typography>
                                <Typography variant="body2" sx={{ fontFamily: "Roboto", fontWeight: 300 }}>
                                    {product?.description}
                                </Typography>
                            </CardContent>
                        </Card>
                        <div className="modal-add-item-product-price">
                            <h5
                                style={{
                                    fontSize: 22,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    color: "red",
                                }}
                            >
                                Valor
                            </h5>
                            <p
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: 20,
                                }}
                            >
                                {`R$ ${product?.price.toLocaleString()}`}
                            </p>
                        </div>
                        <div className="modal-add-item-product-inputs">
                            <TextField
                                margin="normal"
                                fullWidth
                                id="orderObservation"
                                label="Observação"
                                name="orderObservation"
                                autoComplete="orderObservation"
                                placeholder="ex: sem azeitona e sem molho"
                                multiline
                                rows={3}
                                value={observation}
                                onChange={(event) => {
                                    setObservation(event.target.value);
                                }}
                            />
                            <div className="table-store-product-input-qt">
                                <p>Quantidade:</p>
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            setQuantity(quantity - 1);
                                        }}
                                        disabled={quantity <= 1}
                                        color="primary"
                                    >
                                        <RemoveCircleIcon />
                                    </IconButton>
                                    <span>{quantity}</span>
                                    <IconButton
                                        onClick={() => {
                                            setQuantity(quantity + 1);
                                        }}
                                    >
                                        <AddCircleIcon color="primary" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-add-item-product-actions">
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                setModalConfirmationOrderOpen(true);
                            }}
                        >
                            PEDIR
                        </Button>
                        <Button variant="contained" color="error" fullWidth onClick={handleClose}>
                            FECHAR
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
