import { foobleAPI } from "./baseApi";
import TokenManager from "../commons/TokenManager";

import { type IStore } from "../commons/interfaces";

interface ICreateStoreRequest {
    showName: string;
    corporateName: string;
    description: string;
    cpfCnpj: string;
    imageUrl?: string;
}

export interface IUpdateStoreRequest {
    showName?: string;
    corporateName?: string;
    description?: string;
    imageUrl?: string;
    cpfCnpj?: string;
}

interface ICreateStoreResponse {
    id: number;
}

interface IUpdateImageResponse {
    path: string;
}

export async function listStoreByUserAuthenticated() {
    return await foobleAPI.get<IStore[]>("/stores/my-stores");
}

export async function readStoreById(storeId: number) {
    return foobleAPI.get<IStore>(`/stores/${storeId}`);
}

export async function createStore(storeData: ICreateStoreRequest) {
    const { uid } = TokenManager.getTokenData();
    return await foobleAPI.post<ICreateStoreResponse>("/stores", {
        userId: uid,
        ...storeData,
    });
}

export async function updateStoreImage(storeId: number, imageFile: File) {
    const formData = new FormData();
    formData.append("image", imageFile);
    return await foobleAPI.put<IUpdateImageResponse>(`/stores/${storeId}/image`, formData);
}

export async function updateStore(storeId: number, data: IUpdateStoreRequest) {
    return await foobleAPI.put(`/stores/${storeId}`, data);
}
