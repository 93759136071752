import axios from "axios";

interface ICepResult {
    cep: string;
    logradouro: string;
    uf: string;
    localidade: string;
    bairro: string;
    endereco: string;
    complemento: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
}

export async function findCep(cep: string) {
    return axios.get<ICepResult>(`https://viacep.com.br/ws/${cep}/json`);
}
