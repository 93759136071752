import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import DescriptionIcon from "@mui/icons-material/Description";
import TableBarIcon from "@mui/icons-material/TableBar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import LinkDashboardMenu from "../../../components/linkDashboardMenu";

import defaultImage from "../../../../static/images/semImagem.png";

import { useStoreData } from "../../../../contexts/StoreData";

interface Props {
    window?: () => Window;
    mobileOpen: boolean;
    handleDrawerToggle: () => void;
}

export default function Menu(props: Props) {
    const { window, mobileOpen, handleDrawerToggle } = props;

    const { store } = useStoreData();
    const { pathname } = useLocation();

    const container = window !== undefined ? () => window().document.body : undefined;

    const logOut = () => {
        localStorage.clear();
        location.reload();
    };

    useEffect(() => {
        if (mobileOpen) {
            handleDrawerToggle();
        }
    }, [pathname]);

    const menu = (
        <div>
            <CardMedia
                component="img"
                image={store?.imageUrl || defaultImage}
                alt="storeImageMenu"
                sx={{ width: 151, margin: "auto" }}
            />
            <Divider />
            <List>
                <LinkDashboardMenu to="/products" text="Produtos" icon={<InventoryIcon />} />
                <LinkDashboardMenu to="/products-categories" text="Categorias de produtos" icon={<CategoryIcon />} />
                <LinkDashboardMenu to="/orders" text="Pedidos" icon={<DescriptionIcon />} />
                <LinkDashboardMenu to="/tables" text="Mesas" icon={<TableBarIcon />} />
            </List>
            <Divider />
            <List>
                <LinkDashboardMenu to="/profile" text="Perfil" icon={<AccountCircleIcon />} />
                <LinkDashboardMenu to="/settings" text="Configurações" icon={<SettingsIcon />} />
                <Divider />
                <LinkDashboardMenu
                    to=""
                    onClick={() => {
                        logOut();
                    }}
                    isButton
                    text="Sair"
                    icon={<LogoutIcon />}
                />
            </List>
        </div>
    );

    return (
        <Box component="nav" aria-label="mailbox folders">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: 240,
                    },
                }}
            >
                {menu}
            </Drawer>
        </Box>
    );
}
