import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

type IRows = Record<string | "color", any>;

interface IProps {
    rows: IRows[];
}

export default function BasicTable(props: IProps) {
    const { rows } = props;
    const [keys, setKeys] = useState<string[]>([]);

    useEffect(() => {
        const k = Object.keys(rows[0] || []).filter((key) => key !== "color");
        setKeys(k);
    }, [rows]);
    return (
        <TableContainer
            component={Paper}
            sx={{ minWidth: "300px", width: { sm: "320px", md: "max-content" }, marginTop: "10px" }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {keys
                            .filter((k) => k !== "color")
                            .map((key, index) => {
                                if (index === 0) {
                                    return <TableCell key={`key-${key}`}>{key}</TableCell>;
                                }
                                return <TableCell key={`key-${key}`}>{key}</TableCell>;
                            })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow
                            key={`row-${i}`}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                                backgroundColor: row.color,
                            }}
                        >
                            {keys.map((key, index) => {
                                if (index === 0) {
                                    return (
                                        <TableCell key={`cell-${key}-${index}`} component="th" scope="row">
                                            {row[key]}
                                        </TableCell>
                                    );
                                }
                                return <TableCell key={`cell-${key}-${index}`}>{row[key]}</TableCell>;
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
