interface ITableParams {
    storeId: number;
    tableName: number;
}

class TableParamsManager {
    private readonly secret: string;

    constructor() {
        this.secret = "5aBw7ixA1fz";
    }

    private isNumber(value: string | number): boolean {
        return typeof value === "number" || !isNaN(value);
    }

    encode(storeId: number, tableName: number) {
        const value = `${storeId}-${tableName}`;
        const arr = value.split("");
        const newArr: string[] = [];

        for (let i = 0; i < arr.length; i++) {
            if (this.isNumber(value[i])) {
                const index = parseInt(value[i]);
                newArr.push(this.secret[index]);
            } else {
                newArr.push(this.secret[this.secret.length - 1]);
            }
        }
        return btoa(newArr.join(""));
    }

    decode(value: string): ITableParams {
        const arr = atob(value).split("");
        const valueDecoded: string[] = [];
        for (let i = 0; i < arr.length; i++) {
            const key = this.secret.indexOf(arr[i]);
            if (key < 10) {
                valueDecoded.push(key.toString());
            } else {
                valueDecoded.push("-");
            }
        }
        const resultDecoded = valueDecoded.join("");
        const dataParams = resultDecoded.split("-");
        return {
            storeId: parseInt(dataParams[0]),
            tableName: parseInt(dataParams[1]),
        };
    }
}

export default new TableParamsManager();
