import { type ITelephone } from "../commons/interfaces";
import { foobleAPI } from "./baseApi";

export interface ICreateTelephoneRequest {
    ddd: string;
    number: string;
}

export interface ICreateTelephoneResponse {
    id: number;
}

export async function createTelephone(storeId: number, data: ICreateTelephoneRequest) {
    return foobleAPI.post<ICreateTelephoneResponse>("/telephones", { storeId, ...data });
}

export async function listTelephonesByStoreId(storeId: number) {
    return foobleAPI.get<ITelephone[]>(`/telephones/stores/${storeId}`);
}

export async function deleteTelephone(telephoneId: number) {
    return foobleAPI.delete(`/telephones/${telephoneId}`);
}
