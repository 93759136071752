import { jwtDecode } from "jwt-decode";
import StorageManager from "./StorageManager";

interface IJwtPayload {
    uid: number;
    roles: string[];
    email: string;
    username: string;
    exp: number;
    validated: boolean;
}

class TokenManager {
    private accessToken: string | undefined;
    private refreshToken: string | undefined;

    constructor() {
        this.accessToken = StorageManager.getItem("accessToken");
        this.refreshToken = StorageManager.getItem("refreshToken");
    }

    setAccessToken(accessToken: string, refreshToken: string) {
        StorageManager.setItem("accessToken", accessToken);
        StorageManager.setItem("refreshToken", refreshToken);
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }

    setTokenTemp(accessToken: string) {
        StorageManager.setItem("accessToken", accessToken);
        this.accessToken = accessToken;
    }

    getAccessToken() {
        return this.accessToken;
    }

    getRefreshToken() {
        return this.refreshToken;
    }

    clear() {
        this.accessToken = "";
        StorageManager.clear();
    }

    public isAuthenticated(): boolean {
        return !!StorageManager.getItem("accessToken");
    }

    getTokenData(): IJwtPayload {
        if (this.accessToken) {
            return jwtDecode<IJwtPayload>(this.accessToken);
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return {} as IJwtPayload;
    }

    setStayConnect(value: boolean) {
        StorageManager.setStayConnect(value);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TokenManager();
