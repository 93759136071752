import { createContext, useState, type PropsWithChildren, useContext } from "react";
import {
    createInvoiceItem as createInvoiceItemIntegration,
    deleteInvoiceItem as deleteInvoiceItemIntegration,
    updateInvoiceItem as updateInvoiceItemIntegration,
    type ICreateInvoiceItemRequest,
    type IUpdateInvoiceItemRequest,
} from "../../integrations/invoiceItems";
import PageLoading from "../../pages/components/PageLoading";
import { useAlert } from "../Alert";
import { removeNullAndUndefinedFields } from "../../commons/utils";

interface IInvoiceItemsContext {
    createInvoiceItem: (data: ICreateInvoiceItemRequest) => Promise<{ id: number } | undefined>;
    deleteInvoiceItem: (invoiceItemId: number) => Promise<boolean>;
    updateInvoiceItem: (invoiceItemId: number, dataToUpdate: IUpdateInvoiceItemRequest) => Promise<boolean>;
}

const InvoiceItemContext = createContext({} as IInvoiceItemsContext);

export const useInvoiceItems = () => useContext(InvoiceItemContext);

export default function InvoiceItems({ children }: PropsWithChildren<unknown>) {
    const [isLoading, setIsLoading] = useState(false);

    const { showAlert } = useAlert();

    const createInvoiceItem = async (data: ICreateInvoiceItemRequest) => {
        try {
            setIsLoading(true);
            const response = await createInvoiceItemIntegration(data);
            if (response) return response.data;
        } catch (error) {
            showAlert("não conseguimos enviar o seu pedido", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const updateInvoiceItem = async (invoiceItemId: number, dataToUpdate: IUpdateInvoiceItemRequest) => {
        try {
            setIsLoading(true);
            await updateInvoiceItemIntegration(
                invoiceItemId,
                removeNullAndUndefinedFields(dataToUpdate) as IUpdateInvoiceItemRequest
            );
            return true;
        } catch (error) {
            showAlert("Não foi possivel atualizar o status do item", { type: "error" });
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false);
        return false;
    };

    const deleteInvoiceItem = async (invoiceItemId: number) => {
        try {
            setIsLoading(true);
            await deleteInvoiceItemIntegration(invoiceItemId);
            return true;
        } catch (error) {
            showAlert("não deletar este item", { type: "error" });
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false);
        return false;
    };

    return (
        <InvoiceItemContext.Provider value={{ createInvoiceItem, deleteInvoiceItem, updateInvoiceItem }}>
            <PageLoading open={isLoading} />
            {children}
        </InvoiceItemContext.Provider>
    );
}
