import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CircleIcon from "@mui/icons-material/Circle";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";

import { useInvoices } from "../../../../contexts/Invoices";
import { type IInvoiceItem, type IInvoice } from "../../../../commons/interfaces";
import Table from "../../../components/Table";
import "./index.css";
import ModalDelete from "../../../components/ModalDelete";
import { useInvoiceItems } from "../../../../contexts/InvoiceItems";
import { useStoreData } from "../../../../contexts/StoreData";
import NavBar from "../../components/navBar";
import ModalPrint from "./ModalPrint";
import Footer from "../../../components/Footer";
import ModalPrintInvoice from "./ModalPrintInvoice";
import ModalAddItem from "./ModalAddItem";

interface IRow {
    item: string;
    "V. Unitario": number;
    QT: number;
    "V. Total": number;
    "": JSX.Element;
    color?: string;
    mensagem?: string;
}

export default function Order() {
    const [invoice, setInvoice] = useState<IInvoice | undefined>();
    const [rows, setRows] = useState<IRow[]>([]);
    const [modalPrintOpen, setModalPrintOpen] = useState(false);
    const [currentInvoiceItemToPrint, setCurrentInvoiceItemToPrint] = useState<IInvoiceItem | undefined>();
    const [modalPrintInvoiceOpen, setModalPrintInvoiceOpen] = useState(false);
    const [openModalAddItem, setOpenModalAddItem] = useState(false);

    const { store } = useStoreData();
    const { invoiceId } = useParams();
    const navigate = useNavigate();
    const {
        invoicesFromStore,
        setInvoicesFromStore,
        findInvoiceById,
        closeInvoice: closeInvoiceIntegration,
        updateInvoice,
    } = useInvoices();
    const { deleteInvoiceItem, updateInvoiceItem } = useInvoiceItems();

    const deleteItem = async (invoiceItemId: number) => {
        const response = await deleteInvoiceItem(invoiceItemId);
        if (invoice && response) {
            const newInvoiceItems = invoice.invoiceItems.filter((item) => item.id !== invoiceItemId);
            let newTotalPrice = 0;
            for (let i = 0; i < newInvoiceItems.length; i++) {
                newTotalPrice =
                    parseFloat(newTotalPrice.toString()) + parseFloat(newInvoiceItems[i].totalPrice.toString());
            }
            setInvoice({ ...invoice, totalPrice: newTotalPrice, invoiceItems: newInvoiceItems });
            setInvoicesFromStore(
                invoicesFromStore.map((i) => {
                    if (i.id === invoice.id) {
                        return { ...invoice, totalPrice: newTotalPrice, invoiceItems: newInvoiceItems };
                    }
                    return i;
                })
            );
        }
    };

    const removeFeeService = async () => {
        await updateInvoice(parseInt(invoiceId!), { feeService: 0 });
        if (invoice) {
            const invoiceUpdated = await findInvoiceById(parseInt(invoiceId!));
            if (invoiceUpdated) {
                setInvoice(invoiceUpdated);
                setInvoicesFromStore(
                    invoicesFromStore.map((i) => {
                        if (i.id === invoice.id) {
                            return invoiceUpdated;
                        }
                        return i;
                    })
                );
            }
        }
    };

    const closeInvoice = async () => {
        if (invoice) {
            await closeInvoiceIntegration(invoice.id);
            const invoiceUpdated = await findInvoiceById(parseInt(invoiceId!));
            if (invoiceUpdated) {
                setInvoice(invoiceUpdated);
                setInvoicesFromStore(
                    invoicesFromStore.map((i) => {
                        if (i.id === invoice.id) {
                            return invoiceUpdated;
                        }
                        return i;
                    })
                );
            }
        }
    };

    const loadInvoice = async () => {
        if (invoiceId) {
            const response = await findInvoiceById(parseInt(invoiceId));
            if (response) {
                setInvoice(response);
            }
        }
    };

    const markAsPrinted = async (invoiceItemId: number) => {
        const response = await updateInvoiceItem(invoiceItemId, { printStatus: "PRINTED" });
        if (response) {
            await loadInvoice();
        }
    };

    useEffect(() => {
        if (invoiceId && invoicesFromStore.length) {
            const currentInvoice = invoicesFromStore.find((i) => i.id === parseInt(invoiceId));
            if (currentInvoice) {
                setInvoice(currentInvoice);
            }
        } else if (invoiceId) {
            loadInvoice();
        }
    }, [invoicesFromStore, invoiceId]);

    useEffect(() => {
        if (invoice) {
            const rowsData: IRow[] = invoice.invoiceItems.map((it) => {
                const result: IRow = {
                    item: it.productName,
                    "V. Unitario": it.unityPrice,
                    QT: it.quantity,
                    "V. Total": it.totalPrice,
                    "": (
                        <div className="action-item-container">
                            <ModalDelete
                                title={`Tem certeza que deseja excluir ${it.productName} da conta?`}
                                action={async () => deleteItem(it.id)}
                                buttonType="ICON"
                            />
                            <IconButton
                                onClick={() => {
                                    setCurrentInvoiceItemToPrint(it);
                                    setModalPrintOpen(true);
                                }}
                            >
                                <PrintIcon color="primary" />
                            </IconButton>
                            {(it.printStatus === "PENDING" || it.printStatus === "ERROR") && (
                                <Button
                                    variant="contained"
                                    onClick={async () => markAsPrinted(it.id)}
                                    sx={{ width: "120px", fontSize: 10 }}
                                >
                                    Marcar como Impresso
                                </Button>
                            )}
                        </div>
                    ),
                };

                if (it.printStatus === "ERROR") {
                    result.color = "red";
                    result.mensagem = "Erro ao Imprimir";
                } else if (it.printStatus === "PENDING") {
                    result.color = "yellow";
                    result.mensagem = "Impressão Pendente";
                }

                return result;
            });

            try {
                if (!!invoice.closingDatetime && store && !!store.settings?.serviceFee.chargeServiceFee) {
                    const serviceFee = store.settings?.serviceFee;
                    rowsData.push({
                        item: serviceFee.feeName || "Taxa de Serviço",
                        "V. Unitario": invoice.feeService || 0,
                        QT: 1,
                        "V. Total": invoice.feeService || 0,
                        "": (
                            <div className="action-item-container">
                                <ModalDelete
                                    title={`Tem certeza que deseja excluir o item a ${
                                        serviceFee.feeName || "Taxa de Serviço"
                                    } da conta?`}
                                    action={removeFeeService}
                                    buttonType="ICON"
                                />
                            </div>
                        ),
                    });
                }
            } catch (error) {}

            setRows(rowsData);
        }
    }, [invoice]);

    return (
        <div className="invoice-container">
            <ModalAddItem open={openModalAddItem} setOpen={setOpenModalAddItem} invoiceId={invoice?.id || 0} />
            <ModalPrint
                open={modalPrintOpen}
                setModalOpen={setModalPrintOpen}
                invoice={invoice}
                item={currentInvoiceItemToPrint}
                markAsPrinted={markAsPrinted}
            />
            <ModalPrintInvoice invoice={invoice} setModalOpen={setModalPrintInvoiceOpen} open={modalPrintInvoiceOpen} />
            <NavBar />

            <div className="invoice-container-actions" style={{ width: "100%", textAlign: "left" }}>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    voltar
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setModalPrintInvoiceOpen(true);
                    }}
                >
                    IMPRIMIR
                </Button>
                {!invoice?.closingDatetime && (
                    <Button variant="contained" onClick={closeInvoice}>
                        Fechar Conta
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpenModalAddItem(true);
                    }}
                >
                    Adicionar Items
                </Button>
            </div>
            <div className="invoice-container-title">
                <h1 style={{ fontFamily: "Roboto, sans-serif", fontWeight: 400 }}>{`Mesa ${invoice?.tableName} `}</h1>
                {invoice && !!invoice.closingDatetime && (
                    <p className="title-info">{format(new Date(invoice?.createdAt || ""), "dd/MM/yyyy HH:mm")}</p>
                )}
            </div>
            <div className="invoice-container-info">
                <CircleIcon sx={{ width: "15px", color: !invoice?.closingDatetime ? "yellow" : "red" }} />
                <span>{!invoice?.closingDatetime ? "Conta Aberta" : "Conta Fechada"}</span>
            </div>
            <div className="invoice-container-items">
                <h2>{`N°. ${invoice?.id}`}</h2>
                <Table rows={rows} />
            </div>

            <div className="invoice-container-total-price">
                <span>Total</span>
                {invoice && <span>{`R$ ${invoice.totalPrice}`}</span>}
            </div>
            <Footer />
        </div>
    );
}
