import { authAPI } from "./baseApi";

interface ICreateUserAuth {
    username: string;
    email: string;
    password: string;
}

interface ICreateUserResponse {
    id: number;
}

interface IPasswordRecoveryRequest {
    email: string;
    loginPageUrl: string;
}

interface IPasswordRecovery {
    newPassword: string;
}

export async function createUserAuth(user: ICreateUserAuth) {
    return await authAPI.post<ICreateUserResponse>("users", user);
}

export async function passwordRecoveryRequest(data: IPasswordRecoveryRequest) {
    return await authAPI.post("/users/password/recovery/request", data);
}

export async function passwordRecovery(data: IPasswordRecovery) {
    return await authAPI.put("/users/password/recovery", data);
}

export async function validateEmailRequest() {
    return await authAPI.post("/users/email/validate/request");
}

export async function validateEmail(code: string) {
    return await authAPI.post("/users/email/validate", { code });
}
