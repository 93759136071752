import { createContext, useState, type PropsWithChildren, useContext, useEffect } from "react";
import { type IStoreSettings } from "../../commons/interfaces";
import {
    createStoreSettings as createStoreSettingsIntegration,
    updateStoreSettings as updateStoreSettingsIntegration,
    readSettingsByStoreId as readSettingsByStoreIdIntegration,
    type ICreateStoreSettingsRequest,
    type IUpdateStoreSettingsRequest,
} from "../../integrations/settings";
import PageLoading from "../../pages/components/PageLoading";
import { useStoreData } from "../StoreData";
import { useAlert } from "../Alert";

interface IStoreSettingsContext {
    storeSettings: IStoreSettings | undefined;
    setStoreSettings: (settings: IStoreSettings) => void;
    createStoreSettings: (data: ICreateStoreSettingsRequest) => Promise<void>;
    updateStoreSettings: (storeId: number, data: IUpdateStoreSettingsRequest) => Promise<void>;
    readStoreSettingsByStoreId: (storeId: number) => Promise<IStoreSettings | undefined>;
}

export const StoreSettingsContext = createContext({} as IStoreSettingsContext);

export const useStoreSettings = () => useContext(StoreSettingsContext);

export default function StoreSettings({ children }: PropsWithChildren<unknown>) {
    const [isLoading, setIsLoading] = useState(false);
    const [storeSettings, setStoreSettings] = useState<IStoreSettings | undefined>();

    const { store, setStore } = useStoreData();
    const { showAlert } = useAlert();

    const createStoreSettings = async (data: ICreateStoreSettingsRequest) => {
        try {
            setIsLoading(true);
            const response = await createStoreSettingsIntegration(data);
            if (response) {
                if (store) {
                    setStore({ ...store, settings: data.data });
                }
                setStoreSettings(data.data);
                showAlert("Configuração Salva!", { type: "success" });
            }
        } catch (error) {
            showAlert("Tivemos um erro para salvar as configurações", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const updateStoreSettings = async (storeId: number, data: IUpdateStoreSettingsRequest) => {
        try {
            setIsLoading(true);
            const response = await updateStoreSettingsIntegration(storeId, data);
            if (response) {
                if (store) {
                    setStore({ ...store, settings: data.data });
                }
                setStoreSettings(data.data);
                showAlert("Configuração Salva!", { type: "success" });
            }
        } catch (error) {
            showAlert("Tivemos um erro para salvar as configurações", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const readStoreSettingsByStoreId = async (storeId: number) => {
        try {
            setIsLoading(true);
            const response = await readSettingsByStoreIdIntegration(storeId);
            if (response) {
                return response.data;
            }
        } catch (error) {
            showAlert("Tivemos um erro para obter as configurações do estabelecimento", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (store) {
            setStoreSettings(store.settings);
        }
    }, [store]);

    return (
        <StoreSettingsContext.Provider
            value={{
                storeSettings,
                setStoreSettings,
                createStoreSettings,
                updateStoreSettings,
                readStoreSettingsByStoreId,
            }}
        >
            <PageLoading open={isLoading} />
            {children}
        </StoreSettingsContext.Provider>
    );
}
