/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, useState, type PropsWithChildren, useEffect, useContext } from "react";

import TokenManager from "../../commons/TokenManager";
import { refreshToken as refreshTokenIntegration } from "../../integrations/token";

interface ISecurityContext {
    forceRefreshToken: () => Promise<void>;
    getAccessTokenUpdated: () => Promise<string | undefined>;
}

export const SecurityContext = createContext({} as ISecurityContext);

export const useSecurityContext = () => useContext(SecurityContext);

export default function Security({ children }: PropsWithChildren<unknown>) {
    const [accessToken, setAccessToken] = useState(TokenManager.getAccessToken());
    const [refreshToken, setRefreshToken] = useState(TokenManager.getRefreshToken());

    function getTimeForAccessTokenExpire() {
        const tokenData = TokenManager.getTokenData();
        const now = new Date().getTime();
        const exp = tokenData.exp;
        return exp * 1000 - now;
    }

    function verifyIfAccessTokenIsExpired() {
        const difference = getTimeForAccessTokenExpire();

        if (difference < 0) {
            return true;
        }
        return false;
    }

    const saveNewAccessToken = (accessToken: string, refreshToken: string) => {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        TokenManager.setAccessToken(accessToken, refreshToken);
    };

    const getAccessTokenUpdated = async () => {
        if (refreshToken) {
            try {
                const response = await refreshTokenIntegration({
                    refreshToken,
                });
                const { accessToken, refreshToken: newRefreshToken } = response.data;
                saveNewAccessToken(accessToken, newRefreshToken);
                return accessToken;
            } catch (error) {}
        }
    };

    async function forceRefreshToken() {
        if (refreshToken) {
            try {
                const response = await refreshTokenIntegration({
                    refreshToken,
                });
                const { accessToken, refreshToken: newRefreshToken } = response.data;
                saveNewAccessToken(accessToken, newRefreshToken);
            } catch (error) {
                TokenManager.clear();
                TokenManager.clear();
                window.location.href = "/login";
            }
        } else {
            TokenManager.clear();
            window.location.href = "/login";
        }
    }

    async function updateAccessToken() {
        if (accessToken) {
            if (verifyIfAccessTokenIsExpired()) {
                try {
                    const response = await refreshTokenIntegration({
                        refreshToken: refreshToken!,
                    });
                    const data = response.data;
                    setAccessToken(data.accessToken);
                    setRefreshToken(data.refreshToken);
                    TokenManager.setAccessToken(data.accessToken, data.refreshToken);
                } catch (error) {
                    TokenManager.clear();
                    window.location.href = "/login";
                }
            }
        }
        if (!TokenManager.getAccessToken() && !accessToken) {
            setTimeout(updateAccessToken, 5000);
        }
        if (TokenManager.getAccessToken() && !accessToken) {
            if (TokenManager.getAccessToken()) {
                setAccessToken(TokenManager.getAccessToken());
                setRefreshToken(TokenManager.getRefreshToken());
            }
        }
    }
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateAccessToken();
    }, []);

    useEffect(() => {
        const timeForExpire = getTimeForAccessTokenExpire();
        setTimeout(updateAccessToken, timeForExpire + 1000);
    }, [accessToken]);

    return (
        <SecurityContext.Provider value={{ forceRefreshToken, getAccessTokenUpdated }}>
            {children}
        </SecurityContext.Provider>
    );
}
