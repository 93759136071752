import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/*
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6605241595051107"
     crossorigin="anonymous"></script>
*/
export default function AdsAutomatic() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initialPath, setInitialPath] = useState("");
    const [scriptGoogle, setScriptGoogle] = useState<HTMLScriptElement>();
    const { pathname } = useLocation();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6605241595051107";
        script.crossOrigin = "anonymous";
        script.async = true;
        setScriptGoogle(script);
        document.head.appendChild(script);
        setInitialPath(pathname);
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (initialPath !== "") {
            if (scriptGoogle) {
                document.head.removeChild(scriptGoogle);
            }
        }
    }, [pathname]);

    return <></>;
}
