import { createContext, useState, type PropsWithChildren, useContext, useEffect } from "react";
import { type ITelephone } from "../../commons/interfaces";
import {
    type ICreateTelephoneRequest,
    type ICreateTelephoneResponse,
    createTelephone as createTelephoneIntegration,
    listTelephonesByStoreId as listTelephoneByStoreIdIntegration,
    deleteTelephone as deleteTelephoneIntegration,
} from "../../integrations/telephones";
import PageLoading from "../../pages/components/PageLoading";
import { useAlert } from "../Alert";
import { useStoreData } from "../StoreData";

interface ITelephonesContext {
    telephones: ITelephone[];
    setTelephones: (telephones: ITelephone[]) => void;
    createTelephone: (storeId: number, data: ICreateTelephoneRequest) => Promise<ICreateTelephoneResponse | undefined>;
    listTelephoneByStoreId: (storeId: number) => Promise<ITelephone[]>;
    deleteTelephone: (telephoneId: number) => Promise<void>;
}

export const TelephoneContext = createContext({} as ITelephonesContext);

export const useTelephones = () => useContext(TelephoneContext);

export default function Telephones({ children }: PropsWithChildren<unknown>) {
    const [isLoading, setIsLoading] = useState(false);
    const [telephones, setTelephones] = useState<ITelephone[]>([]);

    const { showAlert } = useAlert();
    const { store } = useStoreData();

    const createTelephone = async (storeId: number, data: ICreateTelephoneRequest) => {
        setIsLoading(true);
        try {
            const response = await createTelephoneIntegration(storeId, data);
            if (response) {
                setTelephones([...telephones, { id: response.data.id, ...data }]);
                showAlert("Telefone criado", { type: "success" });
                return response.data;
            }
        } catch (error) {
            showAlert("Não conseguimos salvar o telefone!", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const listTelephoneByStoreId = async (storeId: number) => {
        try {
            setIsLoading(true);
            const response = await listTelephoneByStoreIdIntegration(storeId);
            if (response) {
                setTelephones(response.data);
                return response.data;
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false);
        return [];
    };

    const deleteTelephone = async (telephoneId: number) => {
        setIsLoading(true);
        try {
            await deleteTelephoneIntegration(telephoneId);
            const newList = telephones.filter((t) => t.id !== telephoneId);
            setTelephones(newList);
            showAlert("Telefone Excluido", { type: "success" });
        } catch (error) {
            showAlert("Não conseguimos excluir o telefone!", { type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (store) {
            listTelephoneByStoreId(store.id);
        }
    }, [store]);

    return (
        <TelephoneContext.Provider
            value={{ telephones, setTelephones, createTelephone, listTelephoneByStoreId, deleteTelephone }}
        >
            <PageLoading open={isLoading} />
            {children}
        </TelephoneContext.Provider>
    );
}
